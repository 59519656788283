import * as actionTypes from "./actionTypes";
import * as storageTypes from "../plugins/storageTypes";
import * as actions from "./index";
import {ChartInterface, EmptyChartParamsInterface, FormInterface} from "../declarations";
import {getStorage, setStorage} from "../plugins/storage";
import {
    fillUpChartFormFields,
    getChartByHash,
    getChartFormById,
    initEmptyChart, initEmptyForm,
    isExistChartsInState, setCharInputFieldsToSaved
} from "../../utils/functions/chart";
import axios from "../../axios";
import * as apiEndpoints from "../../utils/consants/apiEndpoints";
import {getAxiosConfigWithToken, getErrorMessageFromResponse} from "../../utils/functions/http";
import _ from "lodash";

export const initChartsFromStorage = () => {
    return (dispatch: any) => {
        dispatch(loadChartsStart());

        return getStorage(storageTypes.CHARTS).then((charts: any) => {
            console.log('[actions/user.ts] initChartsFromStorage done');
            dispatch(loadChartsEnd());
            dispatch(setCharts(charts || []));
        }).catch((er: any) => {
            dispatch(loadChartError(er));
            dispatch(actions.showToast('Az adatok lekérése sikertelen volt. Lehet, hogy nincs internetkapcsolat. Kérem próbálja meg újra.'));
        });
    }
};

export const setCharts = (charts: ChartInterface[]) => {
    return {
        type: actionTypes.SET_CHARTS,
        charts: charts
    }
};

export const updateChartsInStore = (charts: ChartInterface[]) => {
    return (dispatch: any) => {
        dispatch(setCharts(charts));
        console.log('[actions/sync.ts] updateChartsInStore', charts);

        return setStorage(storageTypes.CHARTS, charts).catch((err) => {
            dispatch(loadChartError(err));
            dispatch(actions.showToast('Az mentése sikertelen volt. Kérem próbálja meg újra.'));
        });
    }
};

export const loadChartsStart = () => {
    return {
        type: actionTypes.CHART_START
    }
};

export const loadChartsEnd = () => {
    return {
        type: actionTypes.CHART_END
    }
};

export const loadChartError = (error: any) => {
    return {
        type: actionTypes.LOAD_CHARTS_ERROR,
        error: error
    }
};

export const saveChart = (form: FormInterface, chartHash: string, formHash: any) => {
    return (dispatch: any, getState: any) => {
        dispatch(loadChartsStart());
        let newCharts: ChartInterface[] = [];

        if (isExistChartsInState(getState)) {
            const charts = [...getState().chart.charts];
            const chart = getChartByHash(charts, chartHash);

            if (chart) {
                const chartForm = getChartFormById(chart, formHash);

                chart.last_sync_date = 0;
                chart.event_id = getState().event.id;
                dispatch(actions.showSyncAlert());

                if (chartForm) {
                    fillUpChartFormFields(chartForm, form);
                }
            }

            newCharts = charts;
        }

        return dispatch(updateChartsInStore(newCharts));
    }
};

export const createNewChart = (chartHash: string, formHash: string) => {
    return async (dispatch: any, getState: any) => {
        const charts = [...getState().chart.charts];
        const emptyChartParams: EmptyChartParamsInterface = {
            eventId: getState().event.id,
            chartHash,
            userId: getState().user.id,
            formHash
        };
        const chart: ChartInterface = initEmptyChart(emptyChartParams);

        charts.push(chart);
        await dispatch(updateChartsInStore(charts));
        dispatch(actions.showSyncAlert());
    }
};

export const createNewFormForChart = (chartHash: string, formHash: string, formType: string) => {
    return async (dispatch: any, getState: any) => {
        const updatedCharts: ChartInterface[] = [...getState().chart.charts];
        const chart = updatedCharts.find((chart) => chart.hash === chartHash);
        const emptyForm = initEmptyForm(formType, getState().user.id, formHash);

        if (chart) {
            chart.last_sync_date = 0;
            chart?.forms.push(emptyForm);
            await dispatch(updateChartsInStore(updatedCharts));
            dispatch(actions.showSyncAlert());
        }
    }
};


export const updateChartsSyncDate = (charts: ChartInterface[], lastSyncDate: number) => {
    return (dispatch: any, getState: any) => {
        const updatedCharts = _.cloneDeep(getState().chart.charts);
        const chartHash = charts.map((chart: ChartInterface) => chart.hash);
        console.log('[actions/sync.ts] updateChartsSyncDate');

        updatedCharts.forEach((chart: ChartInterface) => {
            if (chartHash.indexOf(chart.hash) > -1) {
                chart.last_sync_date = lastSyncDate;
            }
        });

        return dispatch(updateChartsInStore(updatedCharts));
    }
};

export const getChartsFromServer = () => {
    return (dispatch: any, getState: any) => {
        const config = getAxiosConfigWithToken(getState().auth.token);

        return axios.post(apiEndpoints.API_SYNC_GET_CHARTS, null, config)
            .then(response => {
                if (response.data.success !== true) {
                    console.error('[actions/sync.ts] API_SYNC_GET_CHARTS', response);
                    dispatch(actions.showToast(getErrorMessageFromResponse(response)));
                    return;
                }

                const charts: ChartInterface[] = response.data.payload;
                console.log('[actions/sync.ts] API_SYNC_GET_CHARTS', charts);
                setCharInputFieldsToSaved(charts);

                return dispatch(updateChartsInStore(charts));
            })
            .catch((e) => {
                console.error('[sync.ts] API_SYNC_GET_CHARTS', e);
                dispatch(actions.showToast('Az adatok lekérése a szerverről sikertelen volt. Lehetséges, hogy nincs internetkapcsolat. Próbálkozzon később.'));
            });

    }
};

export const setChartFormValidity = (chartHash: string, formHash: string) => {
    return (dispatch: any, getState: any) => {
        console.log('[actions/charts.ts] setChartFormValidity');
        const updatedCharts = _.cloneDeep(getState().chart.charts);
        const chart: ChartInterface = updatedCharts.find((chart: ChartInterface) => chart.hash === chartHash);
        const form = chart.forms.find((form) => form.hash === formHash);

        if (form) {
            form.valid = 1;
        }

        return dispatch(updateChartsInStore(updatedCharts));
    }
};
