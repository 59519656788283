import axios from "../../axios";
import * as actions from "../../store/actions";
import * as apiEndpoints from "../../utils/consants/apiEndpoints";
import {getAxiosConfigWithToken, getErrorMessageFromResponse} from "../../utils/functions/http";

export const uploadFile = (data: any) => {
    return async (dispatch: any, getState: any) => {
        const config = getAxiosConfigWithToken(getState().auth.token);
        dispatch(actions.showSpinnerModal());

        try {
            const response = await axios.post(apiEndpoints.API_IMPORT, data, config);
            dispatch(actions.hideSpinnerModal());

            if (response.data.success !== true) {
                console.error('[actions/importer.ts] API_IMPORT', response);
                dispatch(actions.showToast(getErrorMessageFromResponse(response)));
                return;
            }

            return response.data.payload;
        } catch (e) {
            console.error('[actions/importer.ts] API_IMPORT', e);
            dispatch(actions.showToast('Az adatok lekérése a szerverről sikertelen volt. Lehetséges, hogy nincs internetkapcsolat. Próbálkozzon később.'));
            dispatch(actions.hideSpinnerModal());
        }
    };
};
