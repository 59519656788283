import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RouteComponentProps} from "react-router";
import {
    IonButton, IonButtons,
    IonContent, IonHeader, IonModal,
    IonPage, IonProgressBar, IonTitle, IonToolbar, useIonViewDidEnter,
    useIonViewDidLeave,
} from "@ionic/react";
import _ from "lodash";
import {CameraResultType, CameraSource} from "@capacitor/core";
import {useCamera} from "@ionic/react-hooks/camera";
import {useFilesystem} from "@ionic/react-hooks/filesystem";

import FormManager from "../../../components/FormManager/FormManager";
import Header from "../../../components/Header/Header";

import forms from "../../../store/static/forms/chart";
import {
    inputChangedHandler,
    setFormValidity,
    initFormValues, touchFormData
} from "../../../utils/functions/form";
import {getChartByHash, getChartFormById, navigationToChardEditPage} from "../../../utils/functions/chart";
import {
    ButtonHandlerInterface, ChartFormInterface,
    ChartInterface,
    EventInterface,
    FileInterface,
    FormInterface
} from "../../../store/declarations";
import * as actions from "../../../store/actions";
import Spinner from "../../../components/Spinner/Spinner";
import {ChartEditRouteParams} from "../../../store/declarations/route";
import {savePicture} from "../../../utils/functions/photo";
import {routes} from "../../../utils/consants/routes";

interface EditProps extends RouteComponentProps<{
    chartHash: string;
    formType: string;
    formHash: any;
    pageIndex: string;
}> {
}

const Edit: React.FC<EditProps> = (props) => {
    const charts: ChartInterface[] = useSelector((state: any) => state.chart.charts);
    const isChartsLoading: boolean = useSelector((state: any) => state.chart.loading);
    const event: EventInterface = useSelector((state: any) => state.event);

    const dispatch = useDispatch();
    const showSuccessAlert = (message: string) => dispatch(actions.showSuccessAlert(message));
    const saveChart = async (form: FormInterface, chartHash: string, formHash: number) => dispatch(actions.saveChart(form, chartHash, formHash));
    const setChartFormValidity = async (chartHash: string, formHash: string) => dispatch(actions.setChartFormValidity(chartHash, formHash));
    const synChartsFromLocalToServer = async (lastSyncDate: number) => dispatch(actions.uploadChartsToServer(lastSyncDate, true, readFile));
    const showSpinnerModal = useCallback(() => dispatch(actions.showSpinnerModal()), [dispatch]);
    const hideSpinnerModal = useCallback(() => dispatch(actions.hideSpinnerModal()), [dispatch]);

    const {match, history} = props;
    const {getPhoto} = useCamera();
    const [activeForm, updateActiveForm] = useState();
    const [showModal, setShowModal] = useState(false);
    const [nextFormPageIndex, updateNextFormPageIndex] = useState();
    const [formIsValid, updateFormIsValid] = useState(false);
    const [currentChartLoaded, updateCurrentChartLoaded] = useState(false);

    const contentRef = useRef(null);
    const {readFile, writeFile} = useFilesystem();

    const {params} = match;
    const {formType, formHash, chartHash} = params;
    const pageIndex = +params.pageIndex;
    const currentForm = forms[formType];
    let progressBarPercent = (100 / (currentForm.length / (pageIndex))) / 100 || 0;
    let getCurrentChart = useCallback(() => getChartByHash(charts, chartHash), [charts, chartHash]);

    useIonViewDidEnter(() => {
        updateCurrentChartLoaded(false);
    });

    const setNextFormPageIndex = useCallback(() => {
        const nextPage = pageIndex + 1;

        if (currentForm.length >= nextPage) {
            updateNextFormPageIndex(nextPage);
        } else {
            updateNextFormPageIndex(undefined);
        }
    }, [pageIndex, currentForm]);

    const initForm = useCallback(async (chartForm: ChartFormInterface, formPageIndex: number) => {
        console.log('[Edit.tsx] initForm');
        let newForm = _.cloneDeep(currentForm[formPageIndex]);
        const values = chartForm?.input_fields ?? [];

        setNextFormPageIndex();
        await initFormValues(newForm, values, event);
        setFormValidity(newForm, updateFormIsValid);
        updateActiveForm(newForm);
    }, [updateActiveForm, setNextFormPageIndex, event, currentForm]);

    const initCurrentChart = useCallback(async () => {
        console.log('[Edit.tsx] initCurrentChart');
        const formPageIndex = pageIndex - 1;
        const chart = getCurrentChart();

        if (chart !== null && currentForm[formPageIndex]) {
            const chartForm = getChartFormById(chart, formHash);

            if (!chartForm || chartForm.type !== formType) {
                history.push(routes.userHome);
                return;
            }

            await initForm(chartForm, formPageIndex);
            // Need timeout to avoid flashing staticForms
            setTimeout(() => {
                updateCurrentChartLoaded(true);
                scrollToTop();
            }, 500);
        }
    }, [getCurrentChart, initForm, pageIndex, currentForm, formHash, history, formType]);

    useEffect(() => {
        if (!isChartsLoading && !currentChartLoaded) {
            initCurrentChart();
        }
    }, [initCurrentChart, isChartsLoading, currentChartLoaded])

    useIonViewDidLeave(() => {
        updateActiveForm(undefined);
    });

    const onSaveHandler = async (redirectUrl: any) => {
        if (!formIsValid) {
            touchFormData(activeForm, updateActiveForm);
            return;
        }

        if (typeof redirectUrl !== "string") {
            redirectUrl = '/charts';
        }

        if (isAllFormPageValid()) {
            await setChartFormValidity(chartHash, formHash);
        }

        await saveChart(activeForm, chartHash, formHash);
        console.log('[Edit.tsx] saveChart end');

        if (nextFormPageIndex === undefined) {
            initEditChartProcessEnd(redirectUrl);
            return;
        }

        const routeParams: ChartEditRouteParams = {
            chartHash,
            formType,
            formHash,
            formPageIndex: nextFormPageIndex
        };

        updateCurrentChartLoaded(false);
        navigationToChardEditPage(history, routeParams);
    };

    const initEditChartProcessEnd = async (redirectUrl: string) => {
        const lastSyncDate = new Date().getTime();

        console.log('[Edit.tsx] initEditChartProcessEnd');
        showSpinnerModal();
        synChartsFromLocalToServer(lastSyncDate).then(() => {
            console.log('[Edit.tsx] synChartsFromLocalToServer end');
            showSuccessAlert('Az űrlap sikeresen mentve az eszközre.');
            initEnd(redirectUrl);
        }).catch(() => {
            initEnd(redirectUrl);
        });
    };

    const initEnd = (redirectUrl: string) => {
        hideSpinnerModal()
        progressBarPercent = 0;
        history.push(redirectUrl);
    };

    const isAllFormPageValid = () => currentForm.length === pageIndex;

    const onAlternateSaveHandler = () => {
        onSaveHandler(`/charts/${chartHash}/email`);
    };

    const onCancelHandler = () => {
        history.push(routes.userHome)
    };

    const takePhoto = async (inputName: string) => {
        const cameraPhoto = await getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
            quality: 100
        });
        const savedFileImage = await savePicture(cameraPhoto, writeFile);
        updateFormByPhoto(inputName, savedFileImage);
    };

    const updateFormByPhoto = (inputName: string, savedFileImage: FileInterface) => {
        const updatedForm: FormInterface = {...activeForm};
        const updatedFormElement = {...updatedForm.form_data[inputName]};

        updatedFormElement.touched = true;
        updatedFormElement.valid = true;
        updatedFormElement.value = savedFileImage.fileName;
        updatedFormElement.base64Data = savedFileImage.base64Data;
        updatedForm.form_data[inputName] = updatedFormElement;

        setFormValidity(updatedForm, updateFormIsValid);
        updateActiveForm(updatedForm);
    };

    const scrollToTop = () => {
        // @ts-ignore
        contentRef.current.scrollToTop();
    };

    const inputHandler = (event: any, inputIdentifier: number) => {
        console.log('[Edit.tsx] inputHandler');
        inputChangedHandler(event.target.value, inputIdentifier, activeForm, updateActiveForm, updateFormIsValid);
    };

    const privacyButtonClickHandler = () => {
        setShowModal(true);
    };

    const buttonHandlers: ButtonHandlerInterface[] = [{
        inputName: 'privacy_button',
        handler: privacyButtonClickHandler
    }];

    const chartForm = () => {
        if (!currentChartLoaded) {
            return <Spinner/>;
        }

        let alternateProps = {};

        if (isAllFormPageValid()) {
            alternateProps = {
                onAlternateSaveHandler,
                saveButtonText: "Mentés és kilépés"
            }
        }

        return <FormManager
            form={activeForm}
            takePhoto={takePhoto}
            inputChangedHandler={inputHandler}
            formIsValid={formIsValid}
            onSaveHandler={onSaveHandler}
            onCancelHandler={onCancelHandler}
            buttonHandlers={buttonHandlers}
            {...alternateProps}
            {...props}
        />;
    };

    return (
        <IonPage>
            <IonContent scrollEvents={true} ref={contentRef}>
                <Header/>
                <IonProgressBar color="success" value={progressBarPercent}/>

                {chartForm()}

                {/*TODO: should refactor this somehow*/}
                <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
                    <IonHeader translucent>
                        <IonToolbar>
                            <IonTitle>Adatkezelési nyilatkozat</IonTitle>
                            <IonButtons slot="end">
                                <IonButton onClick={() => setShowModal(false)}>Close</IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>

                    <IonContent fullscreen className="ion-padding ion-text-justify" color="light">
                        <h2>INFORMÁCIÓK:</h2>
                        <p>ADATKEZELŐ NEVE: Semmelweis Egészségügyi Kft</p>
                        <p>KÉPVISELŐJE: Kovács Róbert ügyvezető</p>
                        <p>HONLAPJA: www.semmelweiskft.hu</p>
                        <p>AZ ADATKEZELÉS CÉLJA: egészségügyi ellátás biztosítása</p>
                        <p>AZ ADATKEZELÉS JOGALAPJA: Az érintett hozzájárulása</p>
                        <p>A SZEMÉLYES ADATOK CÍMZETTJEI: Adatkezelő és szerződődött adatfeldolgozói</p>

                        <h2>TÁJÉKOZTATÁS AZ ÉRINTETT JOGAIRÓL:</h2>
                        <p>Önnek, mint érintett személynek joga van kérelmezni az adatkezelőtől az Önre vonatkozó
                            személyes
                            adatokhoz való hozzáférést, azok helyesbítését, törlését vagy kezelésének korlátozását, és
                            tiltakozhat az ilyen személyes adatok kezelése ellen, valamint a joga van az
                            adathordozhatósághoz.<br/>
                            Joga van a hozzájárulása bármely időpontban történő visszavonásához, amely nem érinti a
                            visszavonás előtt a hozzájárulás alapján végrehajtott adatkezelés jogszerűségét.
                            Joga van a felügyeleti hatósághoz (Nemzeti Adatvédelmi és Információszabadság Hatóság)
                            panaszt
                            benyújtani.</p>

                        <p>Az adatszolgáltatás előfeltétele az orvosi ellátásnak, de, a személyes adatok megadására nem
                            vagyok köteles. Az adatszolgáltatás elmaradásának lehetséges következménye: az Adatkezelő
                            nem
                            tud teljeskörű szolgáltatást nyújtani.</p>

                        <p>A fenti információkat és tájékoztatást tudomásul vettem, fent megadott személyes adataim
                            fentiekben megjelölt célú kezeléséhez önkéntesen, minden külső befolyás nélkül
                            beleegyezésemet
                            adom.</p>

                        <p>Tudomásul veszem, hogy az ellátás igénybevételével elfogadom a Szolgáltató Általános
                            Szerződési
                            Feltételeit (ÁSZF), ami a www.semmelweiskft.hu oldalon megismerhető.</p>
                    </IonContent>
                </IonModal>
            </IonContent>
        </IonPage>
    );
};

export default React.memo(Edit, (prevProps, nextProps) => prevProps.match.url === nextProps.match.url);
