import {FormDataInterface, FormInterface} from "../../../declarations";

const profile: FormDataInterface = {
    name: {
        elementType: 'input',
        elementConfig: {
            type: 'text'
        },
        value: '',
        label: 'Név',
        validation: {
            required: true,
        },
        valid: false,
        touched: false
    },
    seal_number: {
        elementType: 'input',
        elementConfig: {
            type: 'text'
        },
        value: '',
        label: 'Pecsét szám',
        validation: {
            required: false
        },
        valid: false,
        touched: false,
    }
};

const forms: FormInterface = {
    form_name: "Profile from",
    form_data: profile
};

export default forms;
