import React from 'react';
import {ChartInterface, EventInterface} from "../../store/declarations";
import {IonFab, IonFabButton, IonIcon, IonItem, IonList} from "@ionic/react";
import {RouteComponentProps} from "react-router";
import {add} from "ionicons/icons";
import {useDispatch, useSelector} from "react-redux";
import {uuid} from 'uuidv4';

import Content from "../Content/Content";
import classes from "./ChartList.module.scss";
import * as actions from "../../store/actions";
import {PERSONAL_FORM} from "../../utils/functions/constants";
import {getPersonalFormHash, navigationToChardEditPage} from "../../utils/functions/chart";
import {ChartEditRouteParams} from "../../store/declarations/route";
import {FIRST_FORM_PAGE_INDEX} from "../../utils/consants/charts";
import {isUserHasAccess} from "../../utils/functions/auth";
import {role} from "../../utils/consants/auth";

interface ChartListInterface extends RouteComponentProps {
    charts: ChartInterface[];
}

const ChartList: React.FC<ChartListInterface> = (props) => {
    const event: EventInterface = useSelector((state: any) => state.event);
    const {assignRoles} = useSelector((state: any) => state.user);
    const dispatch = useDispatch();
    const createNewChart = (chartHash: string, formHash: string) => dispatch(actions.createNewChart(chartHash, formHash));

    const {charts, history} = props;

    const initNewChart = () => {
        const chartHash = uuid();
        const formHash = uuid();
        const formType = PERSONAL_FORM;
        const routeParams: ChartEditRouteParams = {
            chartHash,
            formType,
            formHash,
            formPageIndex: FIRST_FORM_PAGE_INDEX
        };

        createNewChart(chartHash, formHash);
        goToEditPage(routeParams);
    };

    const editPersonalFormOnclickHandler = (chartHash: string, isPersonalFormValid: boolean) => {
        const formType = PERSONAL_FORM;
        const formHash = getPersonalFormHash(charts, chartHash);
        const routeParams: ChartEditRouteParams = {
            chartHash,
            formType,
            formHash,
            formPageIndex: FIRST_FORM_PAGE_INDEX
        };

        goToEditPage(routeParams);
    }

    const onClickHandlers = {
        editPersonalFormOnclickHandler
    };

    const goToEditPage = (routeParams: ChartEditRouteParams) => navigationToChardEditPage(history, routeParams);

    const listItems = () => {
        if (!charts?.length) {
            return <h2>Nincsenek kitöltöt űrlapok</h2>;
        }

        return charts
            .sort((prev: ChartInterface, next: ChartInterface) => prev.created_date > next.created_date ? -1 : 1)
            .filter((chart: ChartInterface) => chart.event_id === event.id)
            .map((chart: ChartInterface) => chart.filtered ? null :
                <IonItem lines="none" key={chart.hash}>
                    <Content onClickHandlers={onClickHandlers}
                             {...chart}
                    />
                </IonItem>
            );
    };

    return (
        <>
            <IonList className={classes['ion-list']}>
                {listItems()}
            </IonList>

            {isUserHasAccess([role.doctor], assignRoles) ?
            <IonFab vertical="bottom" horizontal="end" className={classes['add-button']}>
                <IonFabButton color="success" onClick={initNewChart}>
                    <IonIcon icon={add}/>
                </IonFabButton>
            </IonFab> : null }
        </>
    );
};

export default ChartList;
