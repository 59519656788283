import React from "react";
import {NavLink} from "react-router-dom";

import classes from "./ControlButtons.module.scss";
import {IonIcon} from "@ionic/react";
import {documentTextOutline, eyeOutline, mailOpenOutline, pencilOutline} from "ionicons/icons";

interface ChartControlButtonsInterface {
    hash: string;
    editPersonalFormOnclickHandler: any;
    isPersonalFormValid: number;
    isFormsValid?: boolean;
}

const chartControlButtons: React.FC<ChartControlButtonsInterface> = (props) => {
    const {
        hash,
        editPersonalFormOnclickHandler,
        isPersonalFormValid,
        isFormsValid
    } = props;

    return (
        <>
            {isFormsValid ? <>
                <NavLink to={'/charts/' + hash + '/pdf'}>
                    <IonIcon className={[classes["icons"], classes["icons--pull-right"]].join(' ')}
                             icon={documentTextOutline}/>
                </NavLink>

                <NavLink to={'/charts/' + hash + '/email'}>
                    <IonIcon className={[classes["icons"], classes["icons--pull-right"]].join(' ')}
                             icon={mailOpenOutline}/>
                </NavLink>
            </> : null}

            <div className={classes["link"]}
                 onClick={() => editPersonalFormOnclickHandler(hash, isPersonalFormValid)}>
                <IonIcon className={[classes["icons"], classes["icons--pull-right"]].join(' ')}
                         icon={isPersonalFormValid === 0 ? pencilOutline : eyeOutline}/>
            </div>
        </>
    )
};

export default chartControlButtons;
