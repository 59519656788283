import React from "react";
import classes from "./Footer.module.scss";
import { IonRow, IonCol } from "@ionic/react";

const Footer = () => {
  return (
    <IonRow className={classes["footer"]}>
      <IonCol>
        <p>All rights reserved</p>
      </IonCol>
      <IonCol>
        <p className={classes["footer__version"]}>Ver 0.0</p>
      </IonCol>
    </IonRow>
  );
};

export default Footer;
