import React, {useEffect, useState} from 'react';
import {IonContent, IonPage, useIonViewDidLeave, useIonViewWillEnter} from "@ionic/react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import axios from "axios";
import _ from "lodash";

import * as apiEndpoints from "../../../utils/consants/apiEndpoints";
import {getAxiosConfigWithToken, getErrorMessageFromResponse} from "../../../utils/functions/http";
import {inputChangedHandler, setFormArray, setFormValidity, touchFormData} from "../../../utils/functions/form";
import {routes} from "../../../utils/consants/routes";
import form from "../../../store/static/forms/event/event"
import Input from "../../../components/UI/Input/Input";
import ControlButtons from "../../../components/UI/ControlButtons/ControlButtons";
import * as actions from "../../../store/actions";

const Create: React.FC = () => {
    const [activeForm, updateActiveForm] = useState();
    const formElementsArray: any[] = setFormArray(activeForm);
    const token: string = useSelector((state: any) => state.auth.token);
    const config = getAxiosConfigWithToken(token);
    const history = useHistory();
    const dispatch = useDispatch();
    const [formIsValid, updateFormIsValid] = useState(false);

    useIonViewWillEnter(() => {
        updateActiveForm(_.cloneDeep(form));
    });

    useIonViewDidLeave(() => {
        updateActiveForm(null);
    });

    const createEvent = (payload: any) => {
        dispatch(actions.showSpinnerModal());

        axios.post(apiEndpoints.API_EVENT_NEW, payload, config)
            .then(response => {
                if (response.data.success !== true) {
                    console.error('[Events/Edit/Edit.ts] API_EVENT_SHOW', response);
                    dispatch(actions.showToast(getErrorMessageFromResponse(response)));
                    return;
                }

                history.push(routes.eventList);
            })
            .catch((e) => {
                dispatch(actions.showToast(getErrorMessageFromResponse(e)));
                console.error('[event/new.ts] NEW_EVENT', e);
            }).finally(() => dispatch(actions.hideSpinnerModal()));
    }

    const cancelHandler = () => {
        history.push(routes.eventList);
    }

    const onSaveHandler = () => {
        if (!formIsValid) {
            touchFormData(activeForm, updateActiveForm);
            return;
        }

        const payload: { [key: string]: any } = {};

        for (let input in activeForm.form_data) {
            payload[input] = activeForm.form_data[input].value;
        }

        createEvent({event: payload});
    }
    const inputHandler = (event: any, inputIdentifier: number) => {
        inputChangedHandler(event.target.value, inputIdentifier, activeForm, updateActiveForm, updateFormIsValid);
    };

    useEffect(() => {
        if (activeForm) {
            setFormValidity(activeForm);
        }
    }, [activeForm]);

    return (
        <IonPage>
            <IonContent className="ion-padding">
                <h1>Új szemeszter hozzáadása</h1>
                <form className="clearfix">
                    {
                        formElementsArray.map((formElement: any) => (
                            <Input
                                key={formElement.id}
                                inputName={formElement.id}
                                {...formElement.config}
                                changed={(event: any) => inputHandler(event, formElement.id)}
                            />
                        ))
                    }

                    <ControlButtons
                        onCancelHandler={() => {
                            cancelHandler()
                        }}
                        onSaveHandler={onSaveHandler}
                        formIsValid={true}
                        saveButtonText={'Mentés'}
                    />
                </form>
            </IonContent>
        </IonPage>
    );
};

export default Create;
