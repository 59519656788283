import {FormDataInterface} from "../../../../declarations";
import {defaultFormConfig} from "../../helper/helper";

const declaration: FormDataInterface = {
    text_declaration_top: {
        label: '',
        elementType: 'text',
        text: 'text_declaration_top',
        elementConfig: {},
        validation: {
            required: false,
        },
        ...defaultFormConfig
    },
    declaration_1: {
        label: 'declaration_1',
        elementType: 'checkbox',
        elementConfig: {},
        validation: {
            required: true,
        },
        ...defaultFormConfig
    },
    declaration_2: {
        label: 'declaration_2',
        elementType: 'checkbox',
        elementConfig: {},
        validation: {
            required: true,
        },
        ...defaultFormConfig
    },
    declaration_3: {
        label: 'declaration_3',
        elementType: 'checkbox',
        elementConfig: {},
        validation: {
            required: true,
        },
        ...defaultFormConfig
    },
    declaration_4: {
        label: 'declaration_4',
        elementType: 'checkbox',
        elementConfig: {},
        validation: {
            required: true,
        },
        ...defaultFormConfig
    }
};

export default declaration;
