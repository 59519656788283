import personal_data from "./1_personal_data";
import declaration from "./2_declaration";
import privacy from "./3_privacy";
import signatures from "./4_signatures";
import {FormInterface} from "../../../../declarations";

const personal: FormInterface[] = [
    {
        form_name: "Biztosított személyi adatai/Personal particulars of the Insured",
        form_data: personal_data
    },
    {
        form_name: "Biztosítotti nyilatkozat/Insured’s Declaration",
        form_data: declaration,
        info: '(A nyilatkozatok csak a jelölőnégyzet kitöltésével együtt érvényesek!)<br/>(Declarations are only valid if the respective boxes are checked!)'
    },
    {
        form_name: "Nyilatkozatok/Declaration",
        form_data: privacy,
        info: 'A biztosított egészségügyi adatkezeléssel, egészségügyi titoktartási kötelezettség alóli felmentéssel kapcsolatos nyilatkozata STUDIUM szolgáltatásfinanszírozó egészségbiztosításhoz <br/>The Insured’s consent for the processing of the protected health information, waiver of medical confidentiality obligation for STUDIUM fee-for-service health insurance'
    },
    {
        form_name: "Aláírások/Signatures",
        form_data: signatures,
    }
];

export default personal;
