import React from 'react';
import {IonChip, IonContent, IonIcon, IonLabel, IonPage} from "@ionic/react";
import {calendar, cloudUpload, statsChart} from "ionicons/icons";
import {useHistory} from "react-router";
import {routes} from "../../utils/consants/routes";

const Admin: React.FC = (props) => {
    const history = useHistory();

    return (
        <IonPage>
            <IonContent className="ion-padding">
                <h1 className="ion-padding-bottom">Admin</h1>

                <IonChip color="primary" onClick={() => history.push(routes.importer)}>
                    <IonIcon icon={cloudUpload} color="primary"/>
                    <IonLabel>Importer</IonLabel>
                </IonChip>

                <IonChip color="primary" onClick={() => history.push(routes.eventList)}>
                    <IonIcon icon={calendar} color="primary"/>
                    <IonLabel>Szemeszterek</IonLabel>
                </IonChip>

                <IonChip color="primary" onClick={() => history.push(routes.statistics)}>
                    <IonIcon icon={statsChart} color="primary"/>
                    <IonLabel>Statisztikák</IonLabel>
                </IonChip>
            </IonContent>
        </IonPage>
    );
};

export default Admin;
