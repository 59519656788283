import axios from "../../axios";
import * as actions from "../../store/actions";
import * as apiEndpoints from "../../utils/consants/apiEndpoints";
import {getAxiosConfigWithToken, getErrorMessageFromResponse} from "../../utils/functions/http";

export const getPdfByType = (type: string, hash: string) => {
    return (dispatch: any, getState: any) => {
        const config = getAxiosConfigWithToken(getState().auth.token);
        const data = {
            hash,
            type
        };

        dispatch(actions.showSpinnerModal());

        return axios.post(apiEndpoints.API_PDF, data, config)
            .then(response => {
                dispatch(actions.hideSpinnerModal());
                if (response.data.success !== true) {
                    console.error('[actions/pdf.ts] API_PDF', response);
                    dispatch(actions.showToast(getErrorMessageFromResponse(response)));
                    return;
                }

                const documents = response.data.payload;

                if (documents.length) {
                    for (let document of documents) {
                        window.open(process.env.REACT_APP_API_BASE_URL + document)
                    }
                }
            })
            .catch((e) => {
                console.error('[actions/pdf.ts] API_PDF', e);
                dispatch(actions.showToast('Az adatok lekérése a szerverről sikertelen volt. Lehetséges, hogy nincs internetkapcsolat. Próbálkozzon később.'));
                dispatch(actions.hideSpinnerModal());
            });

    }
};
