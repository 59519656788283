import React from 'react';
import {IonButton, IonIcon, IonImg} from "@ionic/react";
import {cameraOutline, image} from "ionicons/icons";

import classes from './Photo.module.scss';

interface PhotoProps {
    elementType: string;
    saved?: boolean;
    base64Data: string;
    takePhoto: any;
    inputName?: string;
}

const photo: React.FC<PhotoProps> = ({elementType, saved, base64Data, takePhoto, inputName}) => {
    const src = base64Data || image;
    const imgPreview = elementType === 'picture' &&
        <IonImg slot="start" className={classes['photo__img']} src={src} alt=""/>;

    return (
        <>
            <div className={classes['photo']}>
                {!saved && <IonButton slot="end" color="success" onClick={() => takePhoto(inputName)}>
                    <IonIcon slot="icon-only" icon={cameraOutline}/>
                </IonButton>}
            </div>

            {imgPreview}
        </>
    );
};

export default photo;
