export const routes = {
    adminHome: '/admin',
    importer: '/importer',
    statistics: '/statistics',
    userHome: '/charts',
    login: '/login',
    sync: '/sync',
    userEdit: '/user/new',
    chartEmail: '/charts/:chartHash/email',
    chartPdf: '/charts/:chartHash/pdf',
    chartEdit: '/charts/:chartHash/:formType/:formHash/:pageIndex',
    eventList: '/events',
    eventNew: '/events/new',
    eventEdit: '/events/edit/:eventID',
};
