import React, {useCallback, useState} from 'react';
import {IonContent, IonPage, useIonViewDidEnter, useIonViewDidLeave} from "@ionic/react";
import {useHistory} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import axios from "axios";

import {inputChangedHandler, setFormValidity, setValidity, touchFormData} from "../../../utils/functions/form";
import {routes} from "../../../utils/consants/routes";
import * as apiEndpoints from "../../../utils/consants/apiEndpoints";
import {getAxiosConfigWithToken, getErrorMessageFromResponse} from "../../../utils/functions/http";
import {keys} from "../../../utils/functions/common";
import Spinner from "../../../components/Spinner/Spinner";
import FormManager from "../../../components/FormManager/FormManager";
import * as actions from "../../../store/actions";
import form from "../../../store/static/forms/event/event"

const EditEvent: React.FC = (props: any) => {
    const token: string = useSelector((state: any) => state.auth.token);

    const dispatch = useDispatch();

    const [isCurrentFormLoaded, updateIsCurrentFormLoaded] = useState(false);
    const [activeForm, updateActiveForm] = useState()
    const [formIsValid, updateFormIsValid] = useState(false);

    const {match} = props;
    const history = useHistory();

    const inputHandler = (event: any, inputIdentifier: number) => {
        inputChangedHandler(event.target.value, inputIdentifier, activeForm, updateActiveForm, updateFormIsValid);
    };

    const initForm = useCallback(() => {
        const newForm = _.cloneDeep(form);
        const payload = {
            id: match.params.eventID
        };
        const config = getAxiosConfigWithToken(token);
        console.log('[Events/Edit/Edit.ts] initForm');

        axios.post(apiEndpoints.API_EVENT_SHOW, payload, config)
            .then((response: any) => {
                if (response.data.success !== true) {
                    console.error('[Events/Edit/Edit.ts] API_EVENT_SHOW', response);
                    dispatch(actions.showToast(getErrorMessageFromResponse(response)));
                    return;
                }

                const {payload} = response.data;
                newForm.form_data['name'].value = payload.name;
                newForm.form_data['start'].value = payload.start;
                newForm.form_data['end'].value = payload.end;
                newForm.form_data['password'].value = payload.password;

                for (let inputName of keys(newForm.form_data)) {
                    newForm.form_data[inputName].valid = setValidity(newForm.form_data[inputName].value, newForm.form_data[inputName].validation);
                }

                setFormValidity(newForm, updateFormIsValid);
                updateActiveForm(newForm);

                // Need timeout to avoid flashing staticForms
                setTimeout(() => {
                    updateIsCurrentFormLoaded(true);
                }, 500);
            })
            .catch((e) => {
                console.error('[Events/Edit/Edit.ts] API_EVENT_SHOW', e);
                dispatch(actions.showToast('Az adatok lekérése a szerverről sikertelen volt. Lehetséges, hogy nincs internetkapcsolat. Próbálkozzon később.'));
            });
    }, [dispatch, match.params.eventID, token]);


    useIonViewDidEnter(() => {
        initForm();
    }, [match.params.id]);

    useIonViewDidLeave(() => {
        updateActiveForm(undefined);
        updateIsCurrentFormLoaded(false);
    });

    const isEndDateGraterThenBeginDate = () => activeForm.form_data['start'].value > activeForm.form_data['end'].value;

    const onSaveHandler = () => {
        if (!formIsValid) {
            touchFormData(activeForm, updateActiveForm);
            return;
        }

        if (isEndDateGraterThenBeginDate()) {
            dispatch(actions.showToast('A kezdő dátum nem lehet nagyobb a befejező dátumnál!'));
            return;
        }

        const form_data = activeForm.form_data;
        const id = match.params.eventID
        const payload = {
            id: parseInt(id),
            name: form_data.name.value,
            password: form_data.password.value,
            end: form_data.end.value,
            start: form_data.start.value
        }
        const config = getAxiosConfigWithToken(token);

        dispatch(actions.showSpinnerModal());

        return axios.put(apiEndpoints.API_EVENT_UPDATE, {event: payload}, config)
            .then(response => {
                if (response.data.success !== true) {
                    console.error('[Event/Edit.ts] API_EVENT_UPDATE', response);
                    dispatch(actions.showToast(getErrorMessageFromResponse(response)));
                    return;
                }

                history.push(routes.eventList);
            })
            .catch((e) => {
                console.error('[Event/Edit.ts] API_EVENT_UPDATE', e);
                dispatch(actions.showToast(getErrorMessageFromResponse(e)));
                console.error('[event/new.ts] UPDATE_EVENT', e);
            }).finally(() => dispatch(actions.hideSpinnerModal()));
    };

    const onCancelHandler = () => {
        props.history.push(routes.eventList)
    };

    const drawFrom = () => {
        if (!isCurrentFormLoaded) {
            return <Spinner/>;
        }

        return <FormManager
            form={activeForm}
            inputChangedHandler={inputHandler}
            formIsValid={true}
            onSaveHandler={onSaveHandler}
            onCancelHandler={onCancelHandler}
            saveButtonText={'Mentés'}
            {...props}
        />;
    };
    return (
        <IonPage>
            <IonContent className="ion-padding">
                <h1>Szemeszter szerkesztése</h1>

                {drawFrom()}
            </IonContent>
        </IonPage>
    );
};

export default EditEvent;
