import {FormDataInterface, FormInterface} from "../../../declarations";

const event: FormDataInterface = {
    name: {
        elementType: 'input',
        elementConfig: {
            type: 'text'
        },
        value: '',
        label: 'Szemeszter',
        validation: {
            required: true,
        },
        valid: false,
        touched: false
    },
    password: {
        elementType: 'input',
        elementConfig: {
            type: 'text'
        },
        value: '',
        label: 'Jelszó',
        validation: {
            required: true
        },
        valid: false,
        touched: false,
    },
    start: {
        elementType: 'date',
        elementConfig: {
            type: 'text'
        },
        value: '',
        label: 'Kezdő dátum',
        validation: {
            required: true
        },
        valid: false,
        touched: false,
        maxDate: false
    },
    end: {
        elementType: 'date',
        elementConfig: {
            type: 'text'
        },
        value: '',
        label: 'Befejező dátum',
        validation: {
            required: true
        },
        valid: false,
        touched: false,
        maxDate: false
    }
};

const forms: FormInterface = {
    form_name: "",
    form_data: event
};

export default forms;
