import React from 'react';
import SignaturePad from "react-signature-canvas";
import {IonButton} from "@ionic/react";

import classes from './Signature.module.scss';

interface SignatureProps {
    changed: any,
    saved?: boolean,
    value?: any
}

const signature: React.FC<SignatureProps> = ({changed, children, saved, value}) => {
    let signaturePad = {
        clear: () => {
        },
        toDataURL: (val?: string) => {
        },
        fromDataURL: (val: string) => {
        },
        getTrimmedCanvas: () => {
            return {
                toDataURL: (val: string) => {
                }
            };
        }
    };

    const canvasProps = {
        width: 500,
        height: 200,
        className: classes['signature__pad']
    };

    const clearSignaturePad = (event: any) => {
        signaturePad.clear();
        event.target.value = '';
        changed(event);
    };

    const signatureEnd = (event: any) => {
        event.target.value = signaturePad.toDataURL();
        changed(event);
    };

    const trimmedDataURL: any = saved === true ? value : false;

    return (
        <div className={[classes['signature']].join(' ')}>
            {trimmedDataURL
                ? <img alt=""
                       src={trimmedDataURL}
                       width={canvasProps.width}
                       className={canvasProps.className}/>
                : <>
                    <SignaturePad
                        canvasProps={canvasProps}
                        onEnd={signatureEnd}
                        ref={(ref: any) => signaturePad = ref}
                    />
                    <IonButton onClick={clearSignaturePad} color="danger">Törlés</IonButton>
                </>}
        </div>
    );
};

export default signature;
