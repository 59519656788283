import classes from "./ControlButtons.module.scss";
import {IonButton} from "@ionic/react";
import React from "react";

interface ControlButtonsInterface {
    onCancelHandler: any;
    onSaveHandler: any;
    onAlternateSaveHandler?: any;
    formIsValid: boolean;
    saveButtonText?: string;
    alternateSaveButtonText?: string;
}

const controlButtons: React.FC<ControlButtonsInterface> = (props) => {
    const {onCancelHandler,
        onSaveHandler,
        onAlternateSaveHandler,
        formIsValid,
        saveButtonText,
        alternateSaveButtonText
    } = props;

    return (
        <div
            className={onAlternateSaveHandler ? [classes['control-buttons'], classes['control-buttons--three-column'], 'ion-padding'].join(' ') : [classes['control-buttons'], 'ion-padding'].join(' ')}>
            <IonButton onClick={onCancelHandler}
                       color="danger"
                       item-end
            >
                Mégse
            </IonButton>

            <IonButton onClick={onSaveHandler}
                       disabled={!formIsValid}
                       item-end
            >
                {saveButtonText || 'Mentés'}
            </IonButton>

            {onAlternateSaveHandler ? <IonButton onClick={onAlternateSaveHandler}
                                                 disabled={!formIsValid}
                                                 item-end>{alternateSaveButtonText || 'Mentés és e-mail küldés'}
            </IonButton> : ''}
        </div>
    )
};

export default controlButtons;
