import {defaultFormConfig} from "../../helper/helper";
import {FormDataInterface} from "../../../../declarations";

const privacy: FormDataInterface = {
    privacy_1: {
        label: 'privacy_1',
        elementType: 'checkbox',
        elementConfig: {},
        validation: {
            required: true,
        },
        ...defaultFormConfig
    },
    privacy_2: {
        label: 'privacy_2',
        elementType: 'checkbox',
        elementConfig: {},
        validation: {
            required: true,
        },
        ...defaultFormConfig
    },
    privacy_4: {
        label: 'privacy_4',
        elementType: 'checkbox',
        elementConfig: {},
        validation: {
            required: true,
        },
        ...defaultFormConfig
    },
    text_privacy: {
        label: 'Semmelweis Egészségügyi Kft.',
        elementType: 'text',
        text: 'text_privacy',
        elementConfig: {},
        validation: {
            required: false
        },
        ...defaultFormConfig
    },
    text_privacy_2: {
        label: 'Europe Assistance Magyarország Kft.',
        elementType: 'text',
        text: 'text_privacy_2',
        elementConfig: {},
        validation: {
            required: false
        },
        ...defaultFormConfig
    },
    text_privacy_3: {
        label: '',
        elementType: 'text',
        text: 'text_privacy_3',
        elementConfig: {},
        validation: {
            required: false
        },
        ...defaultFormConfig
    },
    text_privacy_4: {
        label: '',
        elementType: 'text',
        text: 'text_privacy_4',
        elementConfig: {},
        validation: {
            required: false
        },
        ...defaultFormConfig
    },
    text_declaration: {
        label: '',
        elementType: 'text',
        text: 'text_declaration',
        elementConfig: {},
        validation: {
            required: false,
        },
        ...defaultFormConfig
    }
};

export default privacy;
