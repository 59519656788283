import {FormDataInterface} from "../../../../declarations";
import {defaultFormConfig} from "../../helper/helper";

const personal_data: FormDataInterface = {
    language: {
        label: 'Nyelv/Language',
        elementType: 'language',
        multiple: false,
        elementConfig: {
            options: [
                {
                    "displayName": "Deutsche",
                    "optionValue": "de"
                },
                {
                    "displayName": "English",
                    "optionValue": "en"
                },
            ]
        },
        validation: {
            required: true
        },
        colSize: '12',
        ...defaultFormConfig
    },
    first_name: {
        label: 'Vezetéknév/Last name',
        elementType: 'input',
        elementConfig: {
            type: 'text'
        },
        validation: {
            required: true
        },
        colSize: '6',
        ...defaultFormConfig
    },
    last_name: {
        label: 'Keresztnév/First name',
        elementType: 'input',
        elementConfig: {
            type: 'text'
        },
        validation: {
            required: true
        },
        colSize: '6',
        ...defaultFormConfig
    },
    status: {
        label: 'tanuló/student',
        text: 'Státusz/Position',
        elementType: 'checkbox',
        elementConfig: {},
        validation: {
            required: true,
        },
        colSize: '6',
        ...defaultFormConfig
    },
    sex: {
        label: 'Neme/Sex',
        elementType: 'radio',
        elementConfig: {
            options: [
                {
                    displayName: 'férfi/male',
                    optionValue: 'férfi/male'
                },
                {
                    displayName: 'nő/female',
                    optionValue: 'nő/female'
                }
            ]
        },
        validation: {
            required: true,
        },
        colSize: '6',
        ...defaultFormConfig
    },
    date_of_birth: {
        label: 'Születési idő (év/hó/nap) /Date of birth (year/month/day)',
        elementType: 'date',
        elementConfig: {},
        validation: {
            required: true
        },
        colSize: '6',
        maxDate: true,
        ...defaultFormConfig,
    },
    citizenship: {
        label: 'Állampolgárság/Citizenship',
        elementType: 'select',
        multiple: false,
        elementConfig: {
            options: [
                {"displayName": "Afganisztán", "optionValue": "Afganisztán"},
                {"displayName": "Albánia", "optionValue": "Albánia"},
                {"displayName": "Algéria", "optionValue": "Algéria"},
                {"displayName": "Amerikai Egyesült Államok", "optionValue": "Amerikai Egyesült Államok"},
                {"displayName": "Amerikai Samoa", "optionValue": "Amerikai Samoa"},
                {"displayName": "Andorra", "optionValue": "Andorra"},
                {"displayName": "Angola", "optionValue": "Angola"},
                {"displayName": "Anguilla", "optionValue": "Anguilla"},
                {"displayName": "Antarktisz", "optionValue": "Antarktisz"},
                {"displayName": "Antigua és Barbuda", "optionValue": "Antigua és Barbuda"},
                {"displayName": "Argentína", "optionValue": "Argentína"},
                {"displayName": "Aruba", "optionValue": "Aruba"},
                {"displayName": "Ausztrália", "optionValue": "Ausztrália"},
                {"displayName": "Ausztria", "optionValue": "Ausztria"},
                {"displayName": "Azerbajdzsán", "optionValue": "Azerbajdzsán"},
                {"displayName": "Bahamák", "optionValue": "Bahamák"},
                {"displayName": "Bahrein", "optionValue": "Bahrein"},
                {"displayName": "Banglades", "optionValue": "Banglades"},
                {"displayName": "Barbados", "optionValue": "Barbados"},
                {"displayName": "Belgium", "optionValue": "Belgium"},
                {"displayName": "Belize", "optionValue": "Belize"},
                {"displayName": "Belorusszia", "optionValue": "Belorusszia"},
                {"displayName": "Benin", "optionValue": "Benin"},
                {"displayName": "Bermuda", "optionValue": "Bermuda"},
                {"displayName": "Bhután", "optionValue": "Bhután"},
                {"displayName": "Bolívia", "optionValue": "Bolívia"},
                {"displayName": "Bosznia-Hercegovina", "optionValue": "Bosznia-Hercegovina"},
                {"displayName": "Botswana", "optionValue": "Botswana"},
                {"displayName": "Bouvet-sziget", "optionValue": "Bouvet-sziget"},
                {"displayName": "Brazília", "optionValue": "Brazília"},
                {"displayName": "Brit Indiai-óceáni Terület", "optionValue": "Brit Indiai-óceáni Terület"},
                {"displayName": "Brunei", "optionValue": "Brunei"},
                {"displayName": "Bulgária", "optionValue": "Bulgária"},
                {"displayName": "Burkina Faso", "optionValue": "Burkina Faso"},
                {"displayName": "Burundi", "optionValue": "Burundi"},
                {"displayName": "Chile", "optionValue": "Chile"},
                {"displayName": "Ciprus", "optionValue": "Ciprus"},
                {"displayName": "Cocos-szigetek", "optionValue": "Cocos-szigetek"},
                {"displayName": "Comoros", "optionValue": "Comoros"},
                {"displayName": "Cook-szigetek", "optionValue": "Cook-szigetek"},
                {"displayName": "Costa Rica", "optionValue": "Costa Rica"},
                {"displayName": "Csád", "optionValue": "Csád"},
                {"displayName": "Cseh Köztársaság", "optionValue": "Cseh Köztársaság"},
                {"displayName": "Dánia", "optionValue": "Dánia"},
                {"displayName": "Dél-afrikai Köztársaság", "optionValue": "Dél-afrikai Köztársaság"},
                {
                    "displayName": "Dél-Georgia és Dél-Sandwich-szigetek",
                    "optionValue": "Dél-Georgia és Dél-Sandwich-szigetek"
                },
                {"displayName": "Dominika", "optionValue": "Dominika"},
                {"displayName": "Dominikai Köztársaság", "optionValue": "Dominikai Köztársaság"},
                {"displayName": "Dzsibuti", "optionValue": "Dzsibuti"},
                {"displayName": "Ecuador", "optionValue": "Ecuador"},
                {"displayName": "Egyenlítői Guinea", "optionValue": "Egyenlítői Guinea"},
                {"displayName": "Egyesült államok külső szigetei", "optionValue": "Egyesült államok külső szigetei"},
                {"displayName": "Egyesült Arab Emírségek", "optionValue": "Egyesült Arab Emírségek"},
                {"displayName": "Egyesült Királyság", "optionValue": "Egyesült Királyság"},
                {"displayName": "Egyiptom", "optionValue": "Egyiptom"},
                {"displayName": "Elefántcsontpart", "optionValue": "Elefántcsontpart"},
                {"displayName": "Eritrea", "optionValue": "Eritrea"},
                {"displayName": "Északi-Mariana-szigetek", "optionValue": "Északi-Mariana-szigetek"},
                {"displayName": "Észtország", "optionValue": "Észtország"},
                {"displayName": "Etiópia", "optionValue": "Etiópia"},
                {"displayName": "Falkland-szigetek", "optionValue": "Falkland-szigetek"},
                {"displayName": "Faroe-szigetek", "optionValue": "Faroe-szigetek"},
                {"displayName": "Fidzsi-szigetek", "optionValue": "Fidzsi-szigetek"},
                {"displayName": "Finnország", "optionValue": "Finnország"},
                {"displayName": "Francia déli területek", "optionValue": "Francia déli területek"},
                {"displayName": "Francia Guyana", "optionValue": "Francia Guyana"},
                {"displayName": "Francia Polinézia", "optionValue": "Francia Polinézia"},
                {"displayName": "Franciaország", "optionValue": "Franciaország"},
                {"displayName": "Fülöp-szigetek", "optionValue": "Fülöp-szigetek"},
                {"displayName": "Gabon", "optionValue": "Gabon"},
                {"displayName": "Gambia", "optionValue": "Gambia"},
                {"displayName": "Ghána", "optionValue": "Ghána"},
                {"displayName": "Gibraltár", "optionValue": "Gibraltár"},
                {"displayName": "Görögország", "optionValue": "Görögország"},
                {"displayName": "Grenada", "optionValue": "Grenada"},
                {"displayName": "Grönland", "optionValue": "Grönland"},
                {"displayName": "Grúzia", "optionValue": "Grúzia"},
                {"displayName": "Guadeloupe", "optionValue": "Guadeloupe"},
                {"displayName": "Guam-szigetek", "optionValue": "Guam-szigetek"},
                {"displayName": "Guatemala", "optionValue": "Guatemala"},
                {"displayName": "Guinea", "optionValue": "Guinea"},
                {"displayName": "Guinea-Bissau", "optionValue": "Guinea-Bissau"},
                {"displayName": "Guyana", "optionValue": "Guyana"},
                {"displayName": "Haiti", "optionValue": "Haiti"},
                {"displayName": "Heard- és McDonald-szigetek", "optionValue": "Heard- és McDonald-szigetek"},
                {"displayName": "Holland Antillák", "optionValue": "Holland Antillák"},
                {"displayName": "Hollandia", "optionValue": "Hollandia"},
                {"displayName": "Honduras", "optionValue": "Honduras"},
                {"displayName": "Hongkong", "optionValue": "Hongkong"},
                {"displayName": "Horvátország", "optionValue": "Horvátország"},
                {"displayName": "India", "optionValue": "India"},
                {"displayName": "Indonézia", "optionValue": "Indonézia"},
                {"displayName": "Irak", "optionValue": "Irak"},
                {"displayName": "Irán", "optionValue": "Irán"},
                {"displayName": "Írország", "optionValue": "Írország"},
                {"displayName": "Ismeretlen", "optionValue": "Ismeretlen"},
                {"displayName": "Izland", "optionValue": "Izland"},
                {"displayName": "Izrael", "optionValue": "Izrael"},
                {"displayName": "Jamaica", "optionValue": "Jamaica"},
                {"displayName": "Japán", "optionValue": "Japán"},
                {"displayName": "Jemen", "optionValue": "Jemen"},
                {"displayName": "Jersey", "optionValue": "Jersey"},
                {"displayName": "Jordánia", "optionValue": "Jordánia"},
                {"displayName": "Kajmán-szigetek", "optionValue": "Kajmán-szigetek"},
                {"displayName": "Kambodzsa", "optionValue": "Kambodzsa"},
                {"displayName": "Kamerun", "optionValue": "Kamerun"},
                {"displayName": "Kanada", "optionValue": "Kanada"},
                {"displayName": "Karácsony-szigetek", "optionValue": "Karácsony-szigetek"},
                {"displayName": "Katar", "optionValue": "Katar"},
                {"displayName": "Kazahsztán", "optionValue": "Kazahsztán"},
                {"displayName": "Kenya", "optionValue": "Kenya"},
                {"displayName": "Kína", "optionValue": "Kína"},
                {"displayName": "Kirgizisztán", "optionValue": "Kirgizisztán"},
                {"displayName": "Kiribati", "optionValue": "Kiribati"},
                {"displayName": "Kolumbia", "optionValue": "Kolumbia"},
                {"displayName": "Kongó", "optionValue": "Kongó"},
                {"displayName": "Kongói Demokratikus Köztársaság", "optionValue": "Kongói Demokratikus Köztársaság"},
                {"displayName": "Koreai Köztársaság", "optionValue": "Koreai Köztársaság"},
                {"displayName": "Koreai NDK", "optionValue": "Koreai NDK"},
                {"displayName": "Közép-Afrikai Köztársaság", "optionValue": "Közép-Afrikai Köztársaság"},
                {"displayName": "Kuba", "optionValue": "Kuba"},
                {"displayName": "Kuwait", "optionValue": "Kuwait"},
                {"displayName": "Laosz", "optionValue": "Laosz"},
                {"displayName": "Lengyelország", "optionValue": "Lengyelország"},
                {"displayName": "Lesotho", "optionValue": "Lesotho"},
                {"displayName": "Lettország", "optionValue": "Lettország"},
                {"displayName": "Libanon", "optionValue": "Libanon"},
                {"displayName": "Libéria", "optionValue": "Libéria"},
                {"displayName": "Líbia", "optionValue": "Líbia"},
                {"displayName": "Liechtenstein", "optionValue": "Liechtenstein"},
                {"displayName": "Litvánia", "optionValue": "Litvánia"},
                {"displayName": "Luxemburg", "optionValue": "Luxemburg"},
                {"displayName": "Macedónia", "optionValue": "Macedónia"},
                {"displayName": "Madagaszkár", "optionValue": "Madagaszkár"},
                {"displayName": "Magyarország", "optionValue": "Magyarország"},
                {"displayName": "Makaó", "optionValue": "Makaó"},
                {"displayName": "Malajzia", "optionValue": "Malajzia"},
                {"displayName": "Malawi", "optionValue": "Malawi"},
                {"displayName": "Maldív-szigetek", "optionValue": "Maldív-szigetek"},
                {"displayName": "Mali", "optionValue": "Mali"},
                {"displayName": "Málta", "optionValue": "Málta"},
                {"displayName": "Marokkó", "optionValue": "Marokkó"},
                {"displayName": "Marshall-szigetek", "optionValue": "Marshall-szigetek"},
                {"displayName": "Martinique", "optionValue": "Martinique"},
                {"displayName": "Mauritánia", "optionValue": "Mauritánia"},
                {"displayName": "Mauritius", "optionValue": "Mauritius"},
                {"displayName": "Mayotte", "optionValue": "Mayotte"},
                {"displayName": "Mexikó", "optionValue": "Mexikó"},
                {"displayName": "Mikronézia", "optionValue": "Mikronézia"},
                {"displayName": "Moldova", "optionValue": "Moldova"},
                {"displayName": "Monaco", "optionValue": "Monaco"},
                {"displayName": "Mongólia", "optionValue": "Mongólia"},
                {"displayName": "Montserrat", "optionValue": "Montserrat"},
                {"displayName": "Mozambik", "optionValue": "Mozambik"},
                {"displayName": "Myanmar (Burma)", "optionValue": "Myanmar (Burma)"},
                {"displayName": "Namíbia", "optionValue": "Namíbia"},
                {"displayName": "Nauru", "optionValue": "Nauru"},
                {"displayName": "Németország", "optionValue": "Németország"},
                {"displayName": "Nepál", "optionValue": "Nepál"},
                {"displayName": "Nicaragua", "optionValue": "Nicaragua"},
                {"displayName": "Niger", "optionValue": "Niger"},
                {"displayName": "Nigéria", "optionValue": "Nigéria"},
                {"displayName": "Niue", "optionValue": "Niue"},
                {"displayName": "Norfolk szigetek", "optionValue": "Norfolk szigetek"},
                {"displayName": "Norvégia", "optionValue": "Norvégia"},
                {"displayName": "Nyugat-Szahara", "optionValue": "Nyugat-Szahara"},
                {"displayName": "Nyugat-Szamoa", "optionValue": "Nyugat-Szamoa"},
                {"displayName": "Olaszország", "optionValue": "Olaszország"},
                {"displayName": "Omán", "optionValue": "Omán"},
                {"displayName": "Oroszország", "optionValue": "Oroszország"},
                {"displayName": "Örményország", "optionValue": "Örményország"},
                {"displayName": "Pakisztán", "optionValue": "Pakisztán"},
                {"displayName": "Palau", "optionValue": "Palau"},
                {"displayName": "Palesztína", "optionValue": "Palesztína"},
                {"displayName": "Panama", "optionValue": "Panama"},
                {"displayName": "Papua Új-Guinea", "optionValue": "Papua Új-Guinea"},
                {"displayName": "Paraguay", "optionValue": "Paraguay"},
                {"displayName": "Peru", "optionValue": "Peru"},
                {"displayName": "Pitcairn-szigetek", "optionValue": "Pitcairn-szigetek"},
                {"displayName": "Portugália", "optionValue": "Portugália"},
                {"displayName": "Puerto Rico", "optionValue": "Puerto Rico"},
                {"displayName": "Reunion", "optionValue": "Reunion"},
                {"displayName": "Románia", "optionValue": "Románia"},
                {"displayName": "Ruanda", "optionValue": "Ruanda"},
                {"displayName": "Salamon-szigetek", "optionValue": "Salamon-szigetek"},
                {"displayName": "Salvador", "optionValue": "Salvador"},
                {"displayName": "San Marino", "optionValue": "San Marino"},
                {"displayName": "Sao Tome és Principe", "optionValue": "Sao Tome és Principe"},
                {"displayName": "Seychelle-szigetek", "optionValue": "Seychelle-szigetek"},
                {"displayName": "Sierra Leone", "optionValue": "Sierra Leone"},
                {"displayName": "Spanyolország", "optionValue": "Spanyolország"},
                {"displayName": "Srí Lanka", "optionValue": "Srí Lanka"},
                {"displayName": "Suriname", "optionValue": "Suriname"},
                {"displayName": "Svájc", "optionValue": "Svájc"},
                {"displayName": "Svalbard- és Jan Mayen-szigetek", "optionValue": "Svalbard- és Jan Mayen-szigetek"},
                {"displayName": "Svédország", "optionValue": "Svédország"},
                {"displayName": "Szaúd-Arábia", "optionValue": "Szaúd-Arábia"},
                {"displayName": "Szenegál", "optionValue": "Szenegál"},
                {"displayName": "Szent Ilona", "optionValue": "Szent Ilona"},
                {"displayName": "Szent Kitts és Nevis", "optionValue": "Szent Kitts és Nevis"},
                {"displayName": "Szent Lucia", "optionValue": "Szent Lucia"},
                {"displayName": "Szent Pierre és Miquelon", "optionValue": "Szent Pierre és Miquelon"},
                {"displayName": "Szent Vincent és Grenadine-szigetek","optionValue": "Szent Vincent és Grenadine-szigetek"},
                {"displayName": "Szerbia", "optionValue": "Szerbia"},
                {"displayName": "Montenegró", "optionValue": "Montenegró"},
                {"displayName": "Szingapúr", "optionValue": "Szingapúr"},
                {"displayName": "Szíria", "optionValue": "Szíria"},
                {"displayName": "Szlovákia", "optionValue": "Szlovákia"},
                {"displayName": "Szlovénia", "optionValue": "Szlovénia"},
                {"displayName": "Szomália", "optionValue": "Szomália"},
                {"displayName": "Szudán", "optionValue": "Szudán"},
                {"displayName": "Szváziföld", "optionValue": "Szváziföld"},
                {"displayName": "Tadzsikisztán", "optionValue": "Tadzsikisztán"},
                {"displayName": "Tajvan", "optionValue": "Tajvan"},
                {"displayName": "Tanzánia", "optionValue": "Tanzánia"},
                {"displayName": "Thaiföld", "optionValue": "Thaiföld"},
                {"displayName": "Timor Leste", "optionValue": "Timor Leste"},
                {"displayName": "Togo", "optionValue": "Togo"},
                {"displayName": "Tokelau", "optionValue": "Tokelau"},
                {"displayName": "Tonga", "optionValue": "Tonga"},
                {"displayName": "Törökország", "optionValue": "Törökország"},
                {"displayName": "Trinidad és Tobago", "optionValue": "Trinidad és Tobago"},
                {"displayName": "Tunézia", "optionValue": "Tunézia"},
                {"displayName": "Turks- és Caicos-szigetek", "optionValue": "Turks- és Caicos-szigetek"},
                {"displayName": "Tuvalu", "optionValue": "Tuvalu"},
                {"displayName": "Türkmenisztán", "optionValue": "Türkmenisztán"},
                {"displayName": "Uganda", "optionValue": "Uganda"},
                {"displayName": "Új-Kaledónia", "optionValue": "Új-Kaledónia"},
                {"displayName": "Új-Zéland", "optionValue": "Új-Zéland"},
                {"displayName": "Ukrajna", "optionValue": "Ukrajna"},
                {"displayName": "Uruguay", "optionValue": "Uruguay"},
                {"displayName": "Üzbegisztán", "optionValue": "Üzbegisztán"},
                {"displayName": "Vanuatu", "optionValue": "Vanuatu"},
                {"displayName": "Vatikán állam", "optionValue": "Vatikán állam"},
                {"displayName": "Venezuela", "optionValue": "Venezuela"},
                {"displayName": "Vietnám", "optionValue": "Vietnám"},
                {"displayName": "Virgin-szigetek (Brit)", "optionValue": "Virgin-szigetek (Brit)"},
                {"displayName": "Virgin-szigetek (USA)", "optionValue": "Virgin-szigetek (USA)"},
                {"displayName": "Wallis- és Futuna-szigetek", "optionValue": "Wallis- és Futuna-szigetek"},
                {"displayName": "Zambia", "optionValue": "Zambia"},
                {"displayName": "Zimbabwe", "optionValue": "Zimbabwe"},
                {"displayName": "Zöld-foki-szigetek", "optionValue": "Zöld-foki-szigetek"}
            ]
        },
        validation: {
            required: true
        },
        colSize: '6',
        ...defaultFormConfig
    },
    mobile_phone: {
        label: 'Mobilszám/Mobile Phone',
        elementType: 'input',
        elementConfig: {
            type: 'number'
        },
        validation: {
            required: true
        },
        colSize: '6',
        ...defaultFormConfig
    },
    email: {
        label: 'Email cím/E-mail Address',
        elementType: 'input',
        elementConfig: {
            type: 'email'
        },
        validation: {
            required: true,
            isEmail: true
        },
        colSize: '6',
        ...defaultFormConfig
    },
    policy_no: {
        label: 'Szerződésszám/Policy No',
        elementType: 'input',
        elementConfig: {
            type: 'number'
        },
        validation: {
            required: true
        },
        colSize: '6',
        ...defaultFormConfig
    },
    om_id: {
        label: 'OM azonosító/OM ID',
        elementType: 'input',
        elementConfig: {
            type: 'text'
        },
        validation: {
            required: true
        },
        colSize: '6',
        ...defaultFormConfig
    },
    text: {
        label: 'Biztosítotti jogviszony, biztosítási időszak/ Insurance cover, insurance period',
        elementType: 'text',
        text: 'personal_data_text',
        elementConfig: {},
        validation: {
            required: false,
        },
        ...defaultFormConfig
    }
};

export default personal_data;
