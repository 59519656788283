const translationDe = {
    "Felhasználónév" : "Felhasználónév",
    "Jelszó" : "Jelszó",
    "Nyelv/Language" : "Nyelv/Language",
    "Vezetéknév/Last name": "Vezetéknév/Nachname(n)",
    "Keresztnév/First name" : "Keresztnév/Vorname(n)",
    "Státusz/Position" : "Státusz/Status",
    "tanuló/student" : "tanuló/studierende/r",
    "Neme/Sex" : "Neme/Geschlecht",
    "férfi/male" : "férfi/Männlich",
    "nő/female" : "nő/Weiblich",
    "Születési idő (év/hó/nap) /Date of birth (year/month/day)" : "Születési idő (év/hó/nap) /Geburtsdatum (Tag/Monat/Jahr)",
    "Állampolgárság/Citizenship" : "Állampolgárság/Staatsbürgerschaft",
    "Mobilszám/Mobile Phone" : "Mobilszám/Handynummer",
    "Email cím/E-mail Address" : "Email cím/E-Mail",
    "Szerződésszám/Policy No" : "Szerződésszám/Vertragsnummer",
    "OM azonosító/OM ID" : "OM azonosító/Semmelweis Universität ID Nr.",
    "Biztosítotti jogviszony, biztosítási időszak/ Insurance cover, insurance period" : "Biztosítotti jogviszony, biztosítási időszak/ Versicherungsrechtsverhältnis, Versicherungszeitraum",
    "personal_data_text" : "A Biztosítotti jogviszony a Biztosított Semmelweis Egyetemmel fennálló aktív hallgatói jogviszonyának fennállása időtartamára jön létre, feltéve, hogy a Semmelweis Egyetem a Biztosítónak Biztosítottként bejelentette és a rá vonatkozó biztosítási díjat az adott biztosítási időszakra megfizette. A biztosítási időszakok fél évesek, minden tárgyévben szeptember 1. napjától a következő naptári év február 28. napjáig, illetve március 1. napjától augusztus 31. napjáig tartanak. <br/> Das Versicherungsrechtsverhältnis erstreckt sich auf den Zeitraum, in dem sich der/die Studierende im aktiven Studentenstatus an der Semmelweis Universität befindet, vorausgesetzt, der Versicherungsgesellschaft wurde von der Semmelweis Universität als Versicherungsnehmer der Versicherungsanspruch gemeldet und die entsprechende Versicherungsgebühr für den jeweiligen Zeitraum entrichtet. Die Versicherungszeiträume gelten jeweils für ein halbes Jahr, vom 1. September eines jeden Berichtjahres bis zum 28. Februar des nächsten Kalenderjahres bzw. vom 1. März bis zum 31. August.",
    "text_declaration_top" : "A jelen biztosítotti nyilatkozat a fentiekben megjelölt szerződő (a továbbiakban: szerződő) és a Generali Biztosító Zrt. (a továbbiakban: biztosító) között létrejött Egészségbiztosítási szerződés (a továbbiakban: szerződés) részét képezi. A szerződés részét képező kapcsolódó dokumentumok letölthetők a http://www.semmelweis.hu webhelyről.<br/> Vorliegende Erklärung des/der Versicherten bildet einen Teil des zwischen dem oben genannten Versicherungsnehmer (im Weiteren: Versicherungsnehmer) und der Generali Versicherungs-AG (geschlossene Aktiengesellschaft) – Generali Biztosító Zrt. (im Weiteren: Versicherungsgesellschaft) abgeschlossenen Gesundheitsversicherungsvertrages (im Weiteren: Vertrag). Weitere Teildokumente des Vertrages sind auf der Webseite http://medizinstudium.semmelweis.hu erreichbar.",
    "declaration_1" : "Kérem a fentiekben megjelölt szerződő és a biztosító között létrejött Egészségbiztosítási szerződés hatályának reám, mint biztosítottra való kiterjesztését.<br/>Ich bitte um die Erweiterung der Gültigkeit der zwischen dem oben genannten Versicherungsnehmer und der Versicherungsgesellschaft abgeschlossenen Gesundheitsversicherungsvertrages für meine Person als Versicherte/r.",
    "declaration_2": "Alulírott biztosított kijelentem, hogy tájékoztatást kaptam arról, hogy a szerződő és a biztosító között létrejött Egészségbiztosítási szerződéshez kapcsolódó biztosítotti jogviszony igénylésére tekintettel a szerződő a fentiekben megjelölt adataimat a biztosító, mint adatkezelő részére a biztosítotti jogviszony létrejötte, nyilvántartása és a szolgáltatás teljesítése céljából átadja, mely adatokat a biztosító az Egészségbiztosítási szerződés és a biztosítotti jogviszony alapján nyilván tart és kezel. <br/>Ich Unterschriebene/r Versicherte/r erkläre, dass ich darüber informiert wurde, dass mit Rücksicht auf die Anfrage des Versichertenrechtsverhältnisses im Zusammenhang mit dem Gesundheitsvertrag zwischen dem Versicherungsnehmer und dem Versicherer, übergibt der Vertragsnehmer seine Angaben dem Versicherer, als Datenverwalter aus dem Zweck des Zustandekommens, der Registrierung und der Leistungserfüllung des Vertrages, und diese werden vom Versicherer aufgrund des Gesundheitsvertrages und des Versichertenrechtsverhältnisses registriert und verwaltet.",
    "declaration_3" : "Alulírott biztosított jelen nyilatkozat aláírásával elismerem, hogy a szerződő és a biztosító között létrejött egészségbiztosítási szerződés mellékletét képező „Az ügyféltájékoztató és a biztosítási szerződésre vonatkozó általános rendelkezések” című általános szerződési feltételt, valamint a „Terméktájékoztató a Generali Biztosító Zrt. szolgáltatásfinanszírozó egészségbiztosításról” elnevezésű dokumentumot megismertem, az azokban foglaltakat megértettem és tudomásul vettem, különös tekintettel az egészségügyi szolgáltatások körére, tartalmára, az éves szolgáltatási limit és részlimit, továbbá az önrészek és a kizárások/mentesülések alkalmazására vonatkozó feltételeket. <br/> Als Versicherte/r erkenne ich durch die Unterzeichnung der vorliegenden Erklärung an, dass ich die Anlagen des zwischen dem Versicherungsnehmer und der Versicherungsgesellschaft abgeschlossenen Gesundheitsversicherungsvertrages bildenden Dokumente wie die allgemeinen Vertragsbedingungen mit dem Titel „Kundeninformation und allgemeine Bestimmungen des Versicherungsvertrages“ sowie „Produktbeschreibung über die dienstleistungsfinanzierende Gesundheitsversicherung der Generali Versicherungs-AG – Generali Biztosító Zrt.“ gelesen, verstanden und zur Kenntnis genommen habe, insbesondere hinsichtlich des Erstreckungsbereiches und des Inhaltes der Gesundheitsdienstleistungen, des Jahresdienstleistungslimits und des Teillimits wie auch der für die Selbstbehalte und Ausschlüsse/Befreiungen geltenden Bedingungen.",
    "declaration_4" : "Hozzájárulok ahhoz, hogy a Generali Biztosító Zrt. az általam bejelentett szolgáltatási igényről, annak típusáról, a szolgáltatás teljesítéséről vagy az elutasítás tényéről, illetve a szolgáltatási igény teljesítése esetén a kifizetett szolgáltatási összegről a szerződőt a szerződő ezirányú kérése esetén – tájékoztassa. <br/> Ich gebe meine Zustimmung dafür, dass die Generali Versicherungs-AG – Generali Biztosító Zrt. über den von mir angemeldeten Leistungsanspruch, Leistungstyp, über die von mir gemeldete Leistungserfüllung oder über die Tatsache der Ablehnung bzw. bei Erfüllung des Leistungsanspruches über den ausgezahlten Leistungsbetrag – bei diesbezüglichem Anspruch des Versicherungsnehmers – den Versicherungsnehmer informiert.",
    "Kelt/Date" : "Kelt/Datum:",
    "Biztosított aláírása/Signature of Insured" : "Biztosított aláírása/Unterschrift des/der Versicherten",
    "A biztosított egészségügyi adatkezeléssel, egészségügyi titoktartási kötelezettség alóli felmentéssel kapcsolatos nyilatkozata STUDIUM szolgáltatásfinanszírozó egészségbiztosításhoz <br/> The Insured’s consent for the processing of the protected health information, waiver of medical confidentiality obligation for STUDIUM fee-for-service health insurance" :     "A biztosított egészségügyi adatkezeléssel, egészségügyi titoktartási kötelezettség alóli felmentéssel kapcsolatos nyilatkozata STUDIUM szolgáltatásfinanszírozó egészségbiztosításhoz <br/> Erklärung des/der Versicherten bezüglich der Verwaltung von Gesundheitsdaten, der Befreiung von der Schweigepflicht zur STUDIUM dienstleistungsfinanzierenden Gesundheitsversicherung",
    "privacy_1" : "Alulírott kifejezett hozzájárulásomat adom és egyben felhatalmazom a Generali Biztosító Zrt-t, hogy az egészségi állapotomra vonatkozó, a biztosítotti jogviszonyom (biztosítási szerződésem) létrejöttéhez, módosításához, állományban tartásához, a biztosítási szerződésből származó követelések megítéléséhez, illetve a szolgáltatási igények elbírálásához szükséges személyes adataimat beszerezze és kezelje. <br/>     Hozzájárulok továbbá, hogy a biztosító a fent megjelölt biztosítási szerződés vonatkozásában is felhasználja a fenti célokból az akár a későbbiekben, akár korábban bármikor megadott illetve a birtokába került (a biztosító rendelkezésére álló) egészségügyi adatokat.<br/> Ich Unterzeichnete/r stimme ausdrücklich zu, und gleichzeitig bevollmächtige die Generali Versicherungs AG, die mit meinem Gesundheitszustand zusammenhängenden Daten, die direkt mit dem Abschluss, der Änderung, Verwaltung, Beurteilung der aus dem oben genannten Versicherungsvertrag (meine Versicherungsverträge) einhergehenden Forderungen bzw. die mit der Beurteilung des Versicherungsanspruches direkt zusammenhängenden, unerlässlich erforderlichen Daten einzuholen und zu verwalten.",
    "privacy_2" : "Alulírott biztosított kijelentem, hogy felmentem az egészségi állapotomra vonatkozó személyes adatokat jogszabályi felhatalmazás alapján nyilvántartó intézményeket, személyeket (pl. háziorvos, járó- és fekvőbeteg, valamint egyéb egészségügyi ellátó és szociális intézmények) és szervezeteket (pl. társadalombiztosítási szerv, a mindenkor hatályos magyar jogszabályokban meghatározott, az egészségkárosodás/egészségi állapot mértékének megállapítására jogosult szerv) a titoktartási kötelezettségük alól. <br/>Ich Unterzeichnete/r Versicherte/r erkläre, dass ich die Institutionen, Personen, die die persönlichen Daten bezüglich meines Gesundheitszustandes aufgrund einer Rechtsregelvollmacht verwalten (z.B. Hausärzte/Hausärztinnen, ambulante und stationäre Patienten behandelnde Versorgungs- und Sozialinstitutionen) und Organisationen (z.B.Krankenversicherungsanstalten, laut gültigen, ungarischen Rechtsvorschriften festgelegten, zur Feststellung von Gesundheitsschädigung/Gesundheitszustand berechtigte Organisationen) von ihrer Schweigepflicht.",
    "privacy_4" : "Alulírott kifejezett hozzájárulásomat adom ahhoz, hogy a biztosító a biztosítási szerződéssel kapcsolatos – kockázatvállalással, a követelések megítélésével, kárrendezéssel, együttbiztosítással, viszontbiztosítással összefüggő – az egészségi állapotomra vonatkozó adatokat a biztosító az anyavállalatának, tagállami viszontbiztosítónak, együttbiztosítás esetén tagállami kockázatvállaló biztosítónak –, illetve ellátásszervezői tevékenység esetén annak lefolytatása céljából, a biztosítási titokra vonatkozó rendelkezések betartása mellett a kijelölt egészségügyi szolgáltatónak és/vagy a szolgáltatásszervezőnek, valamint a szolgáltatásszervező egészségügyi szolgáltatást nyújtó szerződött partnereinek továbbítsa. <br/> Ich gebe meine ausdrückliche Zustimmung dazu, dass die Versicherungsgesellschaft bezüglich des mit dem Versicherungsvertrag einhergehenden – Risikohaftung, Forderungsbeurteilung, Schadenregulierung, Gesamtversicherung, Rückversicherung zusammenhängenden – sich auf meinen Gesundheitszustand bezogenen Daten an ihre Muttergesellschaft, Rückversicherungsgesellschaften in den Mitgliedstaaten, im Falle einer Gesamtversicherung der Risikohaftung tragenden Versicherungsgesellschaft in den Mitgliedstaaten – bzw. im Falle von Versorgungsorganisationstätigkeit und Ausübung dieser unter Einhaltung der diesbezüglichen Bestimmungen über das Versicherungsgeheimnis an bestimmte Gesundheitsdienstleister und/oder Dienstleistungsorganisatoren sowie Vertragspartner der Gesundheitsdienstleistung anbietenden Dienstleistungsorganisationen weiterleitet.",
    "Semmelweis Egészségügyi Kft." : "Semmelweis Egészségügyi Kft.",
    "text_privacy" : "Székhelye/Sitz: 1085 Budapest, Üllői út 26. <br/> Cégjegyzékszám/Firmenregisternummer: 01-09-879749",
    "Europe Assistance Magyarország Kft." : "Europe Assistance Magyarország Kft.",
    "text_privacy_2" : "Székhely/Sitz: 1132 Budapest, Váci út 36-38.<br/>Cégjegyzékszám/Firmenregisternummer: 01-10-043228",
    "text_privacy_3" : "Az egészségi állapotra vonatkozó adatokat és a biztosítási titkot képező személyes adatokat a biztosító az adatvédelmi előírások betartása mellett adatfeldolgozó részére, továbbá kiszervezési tevékenység keretében szerződött szakértői (orvosok, orvosszakértők, egészségügyi szolgáltatók) részére szolgáltatási igény rendezésével kapcsolatos szakértői vélemény elkészítése érdekében átadhatja. <br/>Die Versicherungsgesellschaft kann die Daten über den Gesundheitszustand und die Versicherungsgeheimnis bildenden persönlichen Daten unter Einhaltung der Datenschutzvorschriften an den Datenverwalter, des Weiteren aufgrund eines Outsourcing-Vertrages an Gutachter/Gutachterinnen (Ärzte/Ärztinnen, ärztliche Gutachter/innen, Gesundheitsdienstleister) zwecks Gutachtenerstellung in Leistungsanspruchsfällen übergeben.",
    "text_privacy_4" : "A biztosítási szerződéssel kapcsolatos adatkezelésekről, az igénybe vett adatfeldolgozókról, a személyes adatok kezelésével kapcsolatos jogokról és jogorvoslati lehetőségekről részletes tájékoztatást a biztosító adatkezelési tájékoztatójában talál. Az adatkezelési tájékoztató elérhető a generali.hu oldalról.<br/>Detaillierte Informationen über Datenverwaltung des Versicherungsvertrages, in Anspruch genommene Datenverwalter, Verwaltungsrecht persönlicher Daten und Möglichkeiten des Rechtsbehelfs sind in der detaillierten Information über die Datenverwaltung der Versicherungsgesellschaft auf der Webseite generali.hu zu finden.",
    "text_declaration" :"A biztosító és a biztosított kapcsolattartása, valamint a biztosítottak tájékoztatása magyar, angol és német nyelven történik azzal, hogy véleménykülönbség esetén a magyar nyelvű szöveg az irányadó.<br/> Kontakthaltung sowie Informationsvermittlung zwischen der Versicherungsgesellschaft und dem/der Versicherten erfolgt in ungarischer und englischer Sprache, wobei im Falle einer Meinungsverschiedenheit die in ungarischer Sprache verfassten Texte maßgebend sind.",
    "Tanúk/Witnesses" : "Tanúk/Zeugen:",
    "Név/Name": "Név/Name",
    "Cím/Address" : "Cím/Adresse:",
    "signature_of_insurance" : "Aláírás/Unterschrift",
    "Biztosított személyi adatai/Personal particulars of the Insured" : "Biztosított személyi adatai/Persönliche Daten des/der Versicherten",
    "Biztosítotti nyilatkozat/Insured’s Declaration" : "Biztosítotti nyilatkozat/Erklärung des/der Versicherten",
    "(A nyilatkozatok csak a jelölőnégyzet kitöltésével együtt érvényesek!)<br/>(Declarations are only valid if the respective boxes are checked!)" : "(A nyilatkozatok csak a jelölőnégyzet kitöltésével együtt érvényesek!)<br/>(Die Erklärungen sind nur mit Ausfüllen der Markierungskästchen gültig!)",
    "Nyilatkozatok/Declaration" : "Nyilatkozatok/Erklärungen",
    "A biztosított egészségügyi adatkezeléssel, egészségügyi titoktartási kötelezettség alóli felmentéssel kapcsolatos nyilatkozata STUDIUM szolgáltatásfinanszírozó egészségbiztosításhoz <br/>The Insured’s consent for the processing of the protected health information, waiver of medical confidentiality obligation for STUDIUM fee-for-service health insurance" : "A biztosított egészségügyi adatkezeléssel, egészségügyi titoktartási kötelezettség alóli felmentéssel kapcsolatos nyilatkozata STUDIUM szolgáltatásfinanszírozó egészségbiztosításhoz <br/>Erklärung des/der Versicherten bezüglich der Verwaltung von Gesundheitsdaten, der Befreiung von der Schweigepflicht zur STUDIUM dienstleistungsfinanzierenden Gesundheitsversicherung",
    "Aláírások/Signatures" : "Aláírások/Unterschrift"
}

export default translationDe;
