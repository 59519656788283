import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../../utils/functions/common";
import { UserInterface } from "../declarations";

const initialState: UserInterface = {
  id: 0,
  name: "",
  email: "",
  seal_number: 0,
  assignRoles: []
};

const setUser = (state: any, action: any) => {
  return updateObject(state, action.user);
};

const clearUser = (state: any, action: any) => {
  return updateObject(state, initialState);
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return setUser(state, action);
    case actionTypes.CLEAR_USER:
      return clearUser(state, action);
    default:
      return state;
  }
};

export default reducer;
