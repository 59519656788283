import React, {useCallback, useEffect, useState} from 'react';
import {IonContent, IonPage, IonSearchbar} from "@ionic/react";
import {RouteComponentProps} from "react-router";
import {useDispatch, useSelector} from "react-redux";

import ChartsList from "../../../components/ChartList/ChartList";
import Alert from "../../../components/Alert/Alert";
import Header from "../../../components/Header/Header";

import {getFieldValue} from "../../../utils/functions/form";
import * as actions from "../../../store/actions";
import {AlertInterface, ChartInterface, InputFieldInterface} from "../../../store/declarations";
import {getFirstChartFormByType} from "../../../utils/functions/chart";
import {PERSONAL_FORM} from "../../../utils/functions/constants";
import {useFilesystem} from "@ionic/react-hooks/filesystem";

interface ListProps extends RouteComponentProps {
    showSearchBar: boolean;
}

const List: React.FC<ListProps> = (props) => {
    const alerts: AlertInterface[] = useSelector((state: any) => state.alert.alerts);
    const charts: ChartInterface[] = useSelector((state: any) => state.chart.charts);

    const dispatch = useDispatch();
    const hideAlert = (alertIndex: number) => dispatch(actions.hideAlert(alertIndex));
    const setCharts = (charts: ChartInterface[]) => dispatch(actions.setCharts(charts));
    const synChartsFromLocalToServer = async (lastSyncDate: number) => dispatch(actions.uploadChartsToServer(lastSyncDate, true, readFile));
    const showSpinnerModal = useCallback(() => dispatch(actions.showSpinnerModal()), [dispatch]);
    const hideSpinnerModal = useCallback(() => dispatch(actions.hideSpinnerModal()), [dispatch]);
    const showSyncAlert = useCallback(() => dispatch(actions.showSyncAlert()), [dispatch]);

    const [searchQuery, updateSearchQuery] = useState();
    const {readFile} = useFilesystem();

    const initSyncAlert = useCallback(() => {
        let isShowSyncAlert = false;

        if (charts.length) {
            for (let chart of charts) {
                if (chart.last_sync_date === 0) {
                    isShowSyncAlert = true;
                    break;
                }
            }
        }

        if (isShowSyncAlert) {
            showSyncAlert();
        }
    }, [charts, showSyncAlert]);

    useEffect(() => {
        initSyncAlert();
    }, [initSyncAlert]);

    const syncButtonHandler = () => {
        console.log('[List.tsx] syncButtonHandler');
        const lastSyncDate = new Date().getTime();
        showSpinnerModal();

        synChartsFromLocalToServer(lastSyncDate).then(() => {
            hideSpinnerModal();
        }).catch(() => {
            hideSpinnerModal();
        });
    };

    const searchBarChangeHandler = (event: any) => {
        const query = event.detail.value!;

        updateSearchQuery(query);
        doFilter(query);
    };

    const doFilter = (query: string) => {
        if (isNeedFilter(query)) {
            const newFilteredChartList = charts.map((chart: ChartInterface) => {
                const personalForm = getFirstChartFormByType(chart, PERSONAL_FORM);

                if (personalForm) {
                    chart.filtered = shouldFilter(personalForm.input_fields, query);
                }

                return chart;
            });

            setCharts(newFilteredChartList);
        }
    };

    const isNeedFilter = (query: string): boolean => {
        return typeof query !== "undefined" && !!charts.length;
    };

    const shouldFilter = (fields: InputFieldInterface[], query: string): boolean => {
        const inputFields = ['first_name', 'last_name'];
        return !inputFields.filter((fieldName: string) => {
            return getFieldValue(fieldName, fields).toLowerCase().indexOf(query.toLowerCase().trim()) > -1;
        }).length;
    };

    const alertTemplate = alerts.map((alert, key) => {
        if (alert.position === 'sticky') {
            alert.callback = syncButtonHandler;
        }

        return <Alert
            key={key}
            {...alert}
            closeHandler={() => hideAlert(key)}
        />;
    });

    const searchBar = () => {
        if (props.showSearchBar) {
            return <IonSearchbar
                value={searchQuery}
                onIonChange={searchBarChangeHandler}
                showCancelButton="focus"
                animated
                placeholder="Keresés"/>;
        }

        return null;
    };

    return (
        <IonPage>
            <IonContent>
                {searchBar()}
                <Header/>

                <div className="ion-padding">
                    {alertTemplate}
                    <ChartsList charts={charts} {...props}/>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default List;
