import * as actionTypes from "./actionTypes";
import {clearStorage, getStorage, setStorage} from "../plugins/storage";
import * as storageTypes from "../plugins/storageTypes";
import * as actions from "./index";
import {UserInterface} from "../declarations";
import {ADMIN_LOGIN_PATH} from "../../utils/consants/auth";
import {isBrowser} from "../../utils/functions/device";

export const updateUser = (user: UserInterface) => {
    return (dispatch: any) => {
        return setStorage(storageTypes.USER, user)
            .then(() => {
                console.log('[actions/user.ts] updateUser done');
                dispatch(setUser(user));

                if (isBrowser) {
                    dispatch(actions.setAuthRedirectPath(ADMIN_LOGIN_PATH));
                }
            })
            .catch(err => {
                dispatch(actions.showToast('Az adatok mentése sikertelen volt. Kérem próbálja meg újra.'));
            });
    };
};

export const setUser = (user: UserInterface) => {
    return {
        type: actionTypes.SET_USER,
        user
    };
};

export const clearUser = () => {
    return (dispatch: any) => {
        clearStorage(storageTypes.USER)
            .then(() => {
                dispatch(removeUser());
            })
            .catch(err => {
                dispatch(actions.showToast('A tárolt adatok törlése sikertelen volt. Kérem próbálja meg újra.'));
            });
    };
};

export const removeUser = () => {
    return {
        type: actionTypes.CLEAR_USER
    };
};

export const editUser = (updatedUser: any) => {
    return (dispatch: any) => {
        dispatch(updateUser(updatedUser));
        return true;
    }
};

export const initUserFromStorage = () => {
    return (dispatch: any) => {
        return getStorage(storageTypes.USER).then((user: any) => {
            console.log('[actions/user.ts] initUserFromStorage done');
            dispatch(setUser(user));
        });
    }
};
