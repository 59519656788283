import React, {useCallback, useEffect, useState} from 'react';
import {
    IonButton,
    IonContent,
    IonPage,
    useIonViewDidEnter,
    useIonViewDidLeave,
} from "@ionic/react";
import {RouteComponentProps} from "react-router";
import {useDispatch, useSelector} from "react-redux";

import Header from "../../../components/Header/Header";
import {ChartInterface, InputFieldInterface, LooseObject} from "../../../store/declarations";
import Spinner from "../../../components/Spinner/Spinner";
import {getChartByHash} from "../../../utils/functions/chart";
import classes from "./Pdf.module.scss";
import * as actions from "../../../store/actions";

interface PdfProps extends RouteComponentProps<{
    chartHash: string;
}> {
}

const Pdf: React.FC<PdfProps> = (props) => {
    const charts: ChartInterface[] = useSelector((state: any) => state.chart.charts);
    const isChartsLoading: boolean = useSelector((state: any) => state.chart.loading);

    const dispatch = useDispatch();
    const {match, history} = props;
    const [loaded, updateLoaded] = useState(false);
    const [fields, updateFields] = useState<LooseObject>({});
    const getPdfByType = (pdfType: string) => dispatch(actions.getPdfByType(pdfType, match.params.chartHash));
    const getCurrentChart = useCallback(() => getChartByHash(charts, match.params.chartHash), [charts, match]);

    useIonViewDidEnter(() => {
        updateLoaded(false);
        setTimeout(() => {
            updateLoaded(true);
        }, 1);
    });

    const initChart = useCallback(() => {
        const chart = getCurrentChart();

        if (!chart || chart.forms.length === 0) {
            history.push('/');
            return;
        }

        updateFields(setFieldObject(chart));
    }, [getCurrentChart, history]);

    const setFieldObject = (chart: ChartInterface) => {
        let fields: LooseObject = {};

        chart.forms[0].input_fields.forEach((field: InputFieldInterface) => {
            fields[field.name] = field.value;
        });

        return fields;
    };

    useEffect(() => {
        if (!isChartsLoading) {
            initChart();
        }
    }, [initChart, isChartsLoading]);

    useIonViewDidLeave(() => {
        updateLoaded(false);
    });

    const openFile = (pdfType: string) => {
        getPdfByType(pdfType);
    };

    const showTemplate = () => {
        if (!loaded || isChartsLoading) {
            return <Spinner/>
        }

        return (
            <div className="ion-padding">
                <h1 className="ion-padding-bottom">Biztosított neve: {fields.last_name} {fields.first_name}</h1>

                <div className={classes['button-group']}>
                    <IonButton onClick={() => openFile('insurance')}>
                        Biztosítotti nyilatkozat letöltése
                    </IonButton>

                    <IonButton onClick={() => openFile('card')}>
                        Biztosítotti kártya letöltése
                    </IonButton>
                </div>
            </div>
        );
    };

    return (
        <IonPage>
            <IonContent>
                <Header/>

                <div className="ion-padding">
                    {showTemplate()}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default React.memo(Pdf, (prevProps, nextProps) => prevProps.match.params.chartHash === nextProps.match.params.chartHash);
