import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import {
    IonCol,
    IonFab,
    IonFabButton,
    IonIcon,
    IonItem,
    IonList,
    IonRow,
    useIonViewDidEnter,
    useIonViewDidLeave
} from "@ionic/react";
import {RouteComponentProps} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import Moment from "react-moment";
import {add, trash, pencilOutline} from "ionicons/icons";

import {API_EVENT_GET} from "../../utils/consants/apiEndpoints";
import {getAxiosConfigWithToken, getErrorMessageFromResponse} from "../../utils/functions/http";
import {routes} from "../../utils/consants/routes";
import * as apiEndpoints from "../../utils/consants/apiEndpoints";
import {DATE_FORMAT} from "../../utils/consants/common";
import * as actions from "../../store/actions";
import {EventInterface} from "../../store/declarations";
import Spinner from "../Spinner/Spinner";
import classes from "./EventList.module.scss";

interface EventListInterface extends RouteComponentProps {
    events: EventInterface[];
}

const EventList: React.FC<EventListInterface> = () => {
    const [isEventsLoaded, updateIsEventsLoaded] = useState(false);
    const token: string = useSelector((state: any) => state.auth.token);
    const config = getAxiosConfigWithToken(token);
    const [events, setEvents] = useState<any[]>([])
    const payload = {};
    const dispatch = useDispatch();
    const history = useHistory();

    const getEvents = () => {
        axios.post(API_EVENT_GET, payload, config)
            .then((response) => {
                    setEvents(response.data.payload);
                    updateIsEventsLoaded(true);
                }
            )
    };

    useIonViewDidEnter(() => {
        getEvents();
    })

    useIonViewDidLeave(() => {
        updateIsEventsLoaded(false);
    });

    const clickHandler = (event: EventInterface) => {
        dispatch(actions.updateEvent(event))
        history.push(routes.userHome);
    }

    const createEvent = () => {
        history.push(routes.eventNew)
    }

    const editURLWithID = (eventID: number) => {
        return routes.eventEdit.replace(":eventID", eventID.toString());
    }

    const deleteEvent = (eventID: number) => {
        const payload = {
            id: eventID,
            deleted: 1
        }

        return axios.put(apiEndpoints.API_EVENT_UPDATE, {event: payload}, config)
            .then(response => {
                if (response.data.success !== true) {
                    console.error('[Event/List.ts] API_EVENT_UPDATE', response);
                    dispatch(actions.showToast(getErrorMessageFromResponse(response)));
                    return;
                }

                history.go(0);
            })
            .catch((e) => {
                console.error('[Event/List.ts] API_EVENT_UPDATE', e);
            });
    }

    const editEvent = (eventID: number) => {
        history.push(editURLWithID(eventID))
    }

    const listEvents = (events: any) => {
        if (!isEventsLoaded) {
            return <Spinner/>;
        }

        return events.map((event: any) => {
            return (
                <IonItem key={event.id} lines="none">
                    <IonRow className={classes["content"]}>
                        <IonCol>
                            <h2 onClick={() => {
                                clickHandler(event)
                            }} className={classes["content__title"]}>
                                {event.name}
                            </h2>

                            <p className={classes["content__description"]}>
                                <Moment date={event.start} format={DATE_FORMAT}/> - <Moment date={event.end}
                                                                                            format={DATE_FORMAT}/>
                            </p>
                        </IonCol>

                        <IonCol className={classes["content__actions"]}>
                            <div className={classes["link"]} onClick={() => editEvent(event.id)}>
                                <IonIcon className={[classes["icons"], classes["icons--pull-right"]].join(' ')}
                                         icon={pencilOutline}/>
                            </div>
                            <div className={classes["link"]} onClick={() => deleteEvent(event.id)}>
                                <IonIcon className={[classes["icons"], classes["icons--pull-right"]].join(' ')}
                                         icon={trash}/>
                            </div>
                        </IonCol>
                    </IonRow>
                </IonItem>
            );
        });
    }

    return (
        <>
            <IonList className={classes['ion-list']}>
                {listEvents(events)}
            </IonList>
            <IonFab vertical="bottom" horizontal="end" className={classes['add-button']}>
                <IonFabButton color="success" onClick={createEvent}>
                    <IonIcon icon={add}/>
                </IonFabButton>
            </IonFab>
        </>
    );
};

export default EventList;
