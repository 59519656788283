import React, {useRef, useState} from 'react';
import {
    IonButton,
    IonCol,
    IonContent, IonItem, IonLabel,
    IonList, IonListHeader,
    IonPage, IonRadio,
    IonRadioGroup,
    IonRow, useIonViewDidEnter, useIonViewDidLeave
} from "@ionic/react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";

import {API_EVENT_GET} from "../../utils/consants/apiEndpoints";
import {getAxiosConfigWithToken} from "../../utils/functions/http";
import * as actions from "../../store/actions";
import Spinner from "../../components/Spinner/Spinner";


const Importer: React.FC = () => {
    const [isEventsLoaded, updateIsEventsLoaded] = useState(false);
    const fileInput = useRef<HTMLInputElement>(null);
    const [selected, setSelected] = useState<string>();
    const [message, updateMessage] = useState<string>();
    const [events, setEvents] = useState<any[]>([])
    const payload = {};
    const token: string = useSelector((state: any) => state.auth.token);
    const config = getAxiosConfigWithToken(token);
    const dispatch = useDispatch();
    const uploadFile = (formData: any) => dispatch(actions.uploadFile(formData));

    const importButtonHandler = async () => {
        updateMessage('');

        if (!selected) {
            dispatch(actions.showToast('Nincs szemeszter kiválasztva'));
            return;
        }

        if (!fileInput.current || !fileInput.current.files || !fileInput.current.files[0]) {
            dispatch(actions.showToast('Nincs fájl kiválasztva'));
            return;
        }

        const file = fileInput.current.files[0];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('semester', selected);

        const response= await uploadFile(formData);

        if (Array.isArray(response) && response.length) {
            updateMessage('A importálás befejeződött. A következő kötvényszámok már léteznek: ' + response.join(', '));
        } else {
            updateMessage('Az importálás sikeres befejeződött');
        }
    };

    const getEvents = () => {
        axios.post(API_EVENT_GET, payload, config)
            .then((response) => {
                    setEvents(response.data.payload);
                    updateIsEventsLoaded(true);
                }
            )
    };

    const listEvents = (events: any) => {
        if (!isEventsLoaded) {
            return <Spinner/>;
        }

        return events.map((event: any) => {
            return (
                <IonItem key={event.id}>
                    <IonLabel>{event.name}</IonLabel>
                    <IonRadio slot="start" value={event.id}/>
                </IonItem>
            );
        });
    }

    useIonViewDidEnter(() => {
        getEvents();
    })

    useIonViewDidLeave(() => {
        updateIsEventsLoaded(false);
    });

    return (
        <IonPage>
            <IonContent className="ion-padding">
                <h1 className="ion-padding-bottom">Excel importer</h1>
                <IonRow className="ion-padding-bottom">
                    <IonCol>
                        <IonList>
                            <IonRadioGroup value={selected} onIonChange={e => setSelected(e.detail.value)}>
                                <IonListHeader>
                                    <IonLabel>Szemeszter</IonLabel>
                                </IonListHeader>

                                {listEvents(events)}
                            </IonRadioGroup>
                        </IonList>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-padding-bottom">
                    <IonCol>
                        <input type="file" id="file"
                               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                               ref={fileInput}/>
                    </IonCol>
                </IonRow>
                <IonButton onClick={importButtonHandler}>Import indítása</IonButton>
                <h2 className="ion-padding-top">{message}</h2>
            </IonContent>
        </IonPage>
    );
};

export default Importer;
