import React from 'react';
import {IonContent, IonPage} from "@ionic/react";
import {RouteComponentProps} from "react-router";
import {useSelector} from "react-redux";

import {EventInterface} from "../../../store/declarations";
import EventList from "../../../components/EventList/EventList";
import Header from "../../../components/Header/Header";

interface ListProps extends RouteComponentProps {
    showSearchBar: boolean;
}

const List: React.FC<ListProps> = (props) => {
    const events: EventInterface[] = useSelector((state: any) => state.event);

    return (
        <IonPage>
            <IonContent>
                <Header/>

                <div className="ion-padding">
                    <EventList events={events} {...props}/>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default List;
