import React, {useEffect, useRef, useState} from 'react';
import classes from './Translate.module.scss';
import axios from "../../../axios";
import {showToast} from "../../../store/actions";

interface PropsInterface {
    textToTranslate: string;
}

const Translate: React.FC<PropsInterface> = (props) => {

    const {textToTranslate} = props;
    const recentlyUpdated = useRef<{value: boolean, timeout: any}>({value: false, timeout: null});
    const [translatedText, updateTranslatedText] = useState('-');

    const translateText = () => {
        updateTranslatedText('-');
        if (textToTranslate) {
            let fromLang = 'hu';
            let toLang = 'en';
            const API_KEY = 'trnsl.1.1.20200422T222131Z.0eba77f1266504cc.ac5fb4582fc72deccf8404755d2d431adfec2b44';
            let url = `https://translate.yandex.net/api/v1.5/tr.json/translate?key=${API_KEY}`;
            url += `&text=${encodeURI(textToTranslate)}`;
            url += `&lang=${fromLang}-${toLang}`;
            url += `&format=plain`;
            recentlyUpdated.current.value = true;

            axios.get(url).then((response) => {
                if (response.data.text) {
                    updateTranslatedText(response.data.text[0]);
                    setTimeout(() => {
                        recentlyUpdated.current.value = false;
                    }, 100);
                }
            }).catch(() => showToast('A fordítás sikertelen volt. Lehetséges, hogy nincs internetkapcsolat. Próbálkozzon később.'));
        }
    };

    useEffect(() => {
        if (recentlyUpdated.current.value === false) {
            clearTimeout(recentlyUpdated.current.timeout);
            recentlyUpdated.current.timeout = (setTimeout(() => {
                translateText();
            }, 500));
        }
    });

    return (
        <p className={classes.translateText}><i>Translation:</i> {translatedText} {recentlyUpdated.current.value}</p>
    );
};

export default Translate;
