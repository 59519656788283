import React, {useCallback, useEffect, useState} from 'react';
import {
    IonContent, IonItem, IonLabel,
    IonPage,
} from "@ionic/react";
import {initStatistics} from "../../utils/functions/statistics";
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../store/actions";
import {getAxiosConfigWithToken} from "../../utils/functions/http";

const Statistics: React.FC = () => {
    const dispatch = useDispatch();
    const authToken: string = useSelector((state: any) => state.auth.token);
    const axiosConfig = useCallback(() => getAxiosConfigWithToken(authToken), [authToken]);
    const [statistics, updateStatistics] = useState<any>(null);
    const init = useCallback(() => initStatistics(dispatch, axiosConfig()), [dispatch, axiosConfig]);

    useEffect(() => {
        init().then((response: any) => {
            if (response.data.success !== true) {
                console.error('[Statistics.tsx] initStatistics fail', response);
                dispatch(actions.showToast('Hiba történt az adatok lekérésében!'));
                return;
            }

            updateStatistics(response.data.payload);
        });
    }, [init, dispatch]);

    const drawStatistics = () => {
        if (!statistics) {
            return null;
        }

        return (
            <>
                <table>
                    <tbody>
                    <tr>
                        <td>Összes biztosított/kitöltés:</td>
                        <td>{statistics.total}/{statistics.total_filled}</td>
                    </tr>
                    </tbody>
                </table>

                <br/><br/>

                Összes biztosított/szemeszter<br/>

                {statistics.grouped_by_events.map((event: any) => {
                    return (
                        <IonItem key={event.name}>
                            <IonLabel>
                                Szemeszter: {event.name} <br/>
                                Biztosított/kitöltés: {event.total || 0}/{event.total_filled || 0}<br/><br/>
                            </IonLabel>
                        </IonItem>
                    );
                })}
            </>
        );
    };

    return (
        <IonPage>
            <IonContent className="ion-padding">
                <h1 className="ion-padding-bottom">Statisztikák</h1>
                <br/>
                {drawStatistics()}
            </IonContent>
        </IonPage>
    );
};

export default Statistics;
