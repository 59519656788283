const translationEn = {
    "Felhasználónév" : "Felhasználónév",
    "Jelszó" : "Jelszó",
    "Nyelv/Language" : "Nyelv/Language",
    "Vezetéknév/Last name": "Vezetéknév/Last name",
    "Keresztnév/First name" : "Keresztnév/First name",
    "Státusz/Position" : "Státusz/Position",
    "tanuló/student" : "tanuló/student",
    "Neme/Sex" : "Neme/Sex",
    "férfi/male" : "férfi/male",
    "nő/female" : "nő/female",
    "Születési idő (év/hó/nap) /Date of birth (year/month/day)" : "Születési idő (év/hó/nap) /Date of birth (year/month/day)",
    "Állampolgárság/Citizenship" : "Állampolgárság/Citizenship",
    "Mobilszám/Mobile Phone" : "Mobilszám/Mobile Phone",
    "Email cím/E-mail Address" : "Email cím/E-mail Address",
    "Szerződésszám/Policy No" : "Szerződésszám/Policy No.",
    "OM azonosító/OM ID" : "OM azonosító/OM ID",
    "Biztosítotti jogviszony, biztosítási időszak/ Insurance cover, insurance period" : "Biztosítotti jogviszony, biztosítási időszak/ Insurance cover, insurance period",
    "personal_data_text" : "A Biztosítotti jogviszony a Biztosított Semmelweis Egyetemmel fennálló aktív hallgatói jogviszonyának fennállása időtartamára jön létre, feltéve, hogy a Semmelweis Egyetem a Biztosítónak Biztosítottként bejelentette és a rá vonatkozó biztosítási díjat az adott biztosítási időszakra megfizette. A biztosítási időszakok fél évesek, minden tárgyévben szeptember 1. napjától a következő naptári év február 28. napjáig, illetve március 1. napjától augusztus 31. napjáig tartanak. <br/> The insurance cover is established for the period of active student status of the insured person at Semmelweis University provided that the Semmelweis University has reported the student as an insured for the Insurer and has paid the insurance premium for the given insurance period. Each period of insurance cover shall be six months, starts at the 1st of September every year and lasts for the 28th of February the next calendar year and/or starts at the 1st of March and lasts for the 31st of August every calendar year.",
    "text_declaration_top" : "A jelen biztosítotti nyilatkozat a fentiekben megjelölt szerződő (a továbbiakban: szerződő) és a Generali Biztosító Zrt. (a továbbiakban: biztosító) között létrejött Egészségbiztosítási szerződés (a továbbiakban: szerződés) részét képezi. A szerződés részét képező kapcsolódó dokumentumok letölthetők a http://www.semmelweis.hu webhelyről.<br/> This insured’s statement shall be an integral part of the Health Insurance Policy (hereinafter: policy) concluded by and between the Policyholder named above (hereinafter: policyholder) and Generali Biztosító Zrt. (hereinafter: insurance company). Please be advised that you may download all documents which shall be read together with the insurance policy from this website: http://www.semmelweis.hu",
    "declaration_1" : "Kérem a fentiekben megjelölt szerződő és a biztosító között létrejött Egészségbiztosítási szerződés hatályának reám, mint biztosítottra való kiterjesztését.<br/>I hereby request that the coverage of the Health Insurance Policy concluded by and between the policyholder specified below and the insurance company be extended to me as a party insured.",
    "declaration_2": "Alulírott biztosított kijelentem, hogy tájékoztatást kaptam arról, hogy a szerződő és a biztosító között létrejött Egészségbiztosítási szerződéshez kapcsolódó biztosítotti jogviszony igénylésére tekintettel a szerződő a fentiekben megjelölt adataimat a biztosító, mint adatkezelő részére a biztosítotti jogviszony létrejötte, nyilvántartása és a szolgáltatás teljesítése céljából átadja, mely adatokat a biztosító az Egészségbiztosítási szerződés és a biztosítotti jogviszony alapján nyilván tart és kezel. <br/> I, the undersigned insured, hereby declare that I have been duly advised of the fact that within the context of and in relation to my application of insurance coverage under the Health insurance policy concluded between the policyholder and the insurance company the policyholder may disclose my personal data specified above to the insurance company acting as data controller, for the purposes of concluding and administering my insurance coverage and for delivering health insurance services. I understand, furthermore, that the insurance company will record and process such data in relation to the Health Insurance Policy and my insurance coverage thereunder.",
    "declaration_3" : "Alulírott biztosított jelen nyilatkozat aláírásával elismerem, hogy a szerződő és a biztosító között létrejött egészségbiztosítási szerződés mellékletét képező „Az ügyféltájékoztató és a biztosítási szerződésre vonatkozó általános rendelkezések” című általános szerződési feltételt, valamint a „Terméktájékoztató a Generali Biztosító Zrt. szolgáltatásfinanszírozó egészségbiztosításról” elnevezésű dokumentumot megismertem, az azokban foglaltakat megértettem és tudomásul vettem, különös tekintettel az egészségügyi szolgáltatások körére, tartalmára, az éves szolgáltatási limit és részlimit, továbbá az önrészek és a kizárások/mentesülések alkalmazására vonatkozó feltételeket. <br/> By virtue of signing this declaration I, as an insured, hereby acknowledge to have received the schedules of the health insurance policy concluded between the policyholder and the insurance company, i.e. the Customer Information and General Provisions Governing Insurance Policies, and the Product Information on the Fee-for-Service Health Insurance of Generali Biztosító Zrt, and that I have read, understood and acknowledge the terms set forth therein, particularly the provisions on the range and content of medical and health care services, the annual limits and pro rata limits applicable to insurance benefits, the application of deductibles as well as the exclusions/exemptions.",
    "declaration_4" : "Hozzájárulok ahhoz, hogy a Generali Biztosító Zrt. az általam bejelentett szolgáltatási igényről, annak típusáról, a szolgáltatás teljesítéséről vagy az elutasítás tényéről, illetve a szolgáltatási igény teljesítése esetén a kifizetett szolgáltatási összegről a szerződőt a szerződő ezirányú kérése esetén – tájékoztassa. <br/> I hereby consent that Generali Biztosító Zrt. may inform the Policyholder about any insurance claim I notify, the type of benefit claimed, the acceptance or rejection of such claim, the amount of benefit payout made in respect of the claim, if such information is specifically requested by the Policyholder.",
    "Kelt/Date" : "Kelt/Date",
    "Biztosított aláírása/Signature of Insured" : "Biztosított aláírása/Signature of Insured",
    "A biztosított egészségügyi adatkezeléssel, egészségügyi titoktartási kötelezettség alóli felmentéssel kapcsolatos nyilatkozata STUDIUM szolgáltatásfinanszírozó egészségbiztosításhoz <br/> The Insured’s consent for the processing of the protected health information, waiver of medical confidentiality obligation for STUDIUM fee-for-service health insurance" :     "A biztosított egészségügyi adatkezeléssel, egészségügyi titoktartási kötelezettség alóli felmentéssel kapcsolatos nyilatkozata STUDIUM szolgáltatásfinanszírozó egészségbiztosításhoz <br/> The Insured’s consent for the processing of the protected health information, waiver of medical confidentiality obligation for STUDIUM fee-for-service health insurance",
    "privacy_1" : "Alulírott kifejezett hozzájárulásomat adom és egyben felhatalmazom a Generali Biztosító Zrt-t, hogy az egészségi állapotomra vonatkozó, a biztosítotti jogviszonyom (biztosítási szerződésem) létrejöttéhez, módosításához, állományban tartásához, a biztosítási szerződésből származó követelések megítéléséhez, illetve a szolgáltatási igények elbírálásához szükséges személyes adataimat beszerezze és kezelje. <br/>     Hozzájárulok továbbá, hogy a biztosító a fent megjelölt biztosítási szerződés vonatkozásában is felhasználja a fenti célokból az akár a későbbiekben, akár korábban bármikor megadott illetve a birtokába került (a biztosító rendelkezésére álló) egészségügyi adatokat.<br/> I, the undersigned, hereby give Generali Biztosító Zrt specific consent and permission to collect and process my personal data directly related to my health and/or my medical conditions (health data) for the purposes of concluding, modifying and administering my insurance coverage (insurance policy) as well as for assessing and settling insurance claims arising out of or under the insurance policy. I hereby give the insurance company specific consent and permission, furthermore, to use the health data collected or otherwise obtained (made available to the insurance company) for the purposes specified above any time, whether subsequently or before, in respect of the insurance policy referred to above.",
    "privacy_2" : "Alulírott biztosított kijelentem, hogy felmentem az egészségi állapotomra vonatkozó személyes adatokat jogszabályi felhatalmazás alapján nyilvántartó intézményeket, személyeket (pl. háziorvos, járó- és fekvőbeteg, valamint egyéb egészségügyi ellátó és szociális intézmények) és szervezeteket (pl. társadalombiztosítási szerv, a mindenkor hatályos magyar jogszabályokban meghatározott, az egészségkárosodás/egészségi állapot mértékének megállapítására jogosult szerv) a titoktartási kötelezettségük alól. <br/>I, the undersigned insured, declare that I hereby release and discharge the institutions and persons (e.g. general practitioners, outpatient and inpatient medical facilities and other health care and social institutes) as well as government organizations (e.g. the social insurance agency, the body authorized by the effective legislation to determine a degree of disability/physical or mental impairment) who/which keep records of such protected health information to comply with the law, from their confidentiality obligation.",
    "privacy_4" : "Alulírott kifejezett hozzájárulásomat adom ahhoz, hogy a biztosító a biztosítási szerződéssel kapcsolatos – kockázatvállalással, a követelések megítélésével, kárrendezéssel, együttbiztosítással, viszontbiztosítással összefüggő – az egészségi állapotomra vonatkozó adatokat a biztosító az anyavállalatának, tagállami viszontbiztosítónak, együttbiztosítás esetén tagállami kockázatvállaló biztosítónak –, illetve ellátásszervezői tevékenység esetén annak lefolytatása céljából, a biztosítási titokra vonatkozó rendelkezések betartása mellett a kijelölt egészségügyi szolgáltatónak és/vagy a szolgáltatásszervezőnek, valamint a szolgáltatásszervező egészségügyi szolgáltatást nyújtó szerződött partnereinek továbbítsa. <br/> By virtue of signing this form I hereby agree that the insurance company may forward my medical data related to the insurance policy – and to the extent required for the purposes of underwriting, claims assessment, claims settlement, co-insurance, and re-insurance – to its parent company, to any re-insurance company seated in a member state, or in the case of co-insurance to a risk sharing insurance company seated in a member state, or for the purposes of medical management services to the designated health care provider and/or to the medical management service provider as well as to any of its contracted partners providing medical and health services, in compliance with the provisions on the confidential treatment of insurance information insurance.",
    "Semmelweis Egészségügyi Kft." : "Semmelweis Egészségügyi Kft.",
    "text_privacy" : "Székhelye/Seat: 1085 Budapest, Üllői út 26. <br/> Cégjegyzékszám/Company registration number: 01-09-879749",
    "Europe Assistance Magyarország Kft." : "Europe Assistance Magyarország Kft.",
    "text_privacy_2" : "Székhely/Seat: 1132 Budapest, Váci út 36-38.<br/>Cégjegyzékszám/Company registration number: 01-10-043228",
    "text_privacy_3" : "Az egészségi állapotra vonatkozó adatokat és a biztosítási titkot képező személyes adatokat a biztosító az adatvédelmi előírások betartása mellett adatfeldolgozó részére, továbbá kiszervezési tevékenység keretében szerződött szakértői (orvosok, orvosszakértok, egészségügyi szolgáltatók) részére szolgáltatási igény rendezésével kapcsolatos szakértői vélemény elkészítése érdekében átadhatja. <br/>In compliance with data protection regulations, the insurance company may disclose protected health data and confidential data related to this insurance contact to the data processor, or to its contracted experts (physicians, medical experts, health care service providers) within the framework of outsourcing activities, for the purposes of preparing expert opinions as required for the settlement of claims.",
    "text_privacy_4" : "A biztosítási szerződéssel kapcsolatos adatkezelésekről, az igénybe vett adatfeldolgozókról, a személyes adatok kezelésével kapcsolatos jogokról és jogorvoslati lehetőségekről részletes tájékoztatást a biztosító adatkezelési tájékoztatójában talál. Az adatkezelési tájékoztató elérhető a generali.hu oldalról.<br/>The detailed rules of data processing, data processors engaged, rights and remedies related to the processing of personal data are set out in the data privacy notice of the insurance company. The data privacy notice is available from the generali.hu website.",
    "text_declaration" :"A biztosító és a biztosított kapcsolattartása, valamint a biztosítottak tájékoztatása magyar, angol és német nyelven történik azzal, hogy véleménykülönbség esetén a magyar nyelvű szöveg az irányadó.<br/> Communication between the Insurance Company and the Insured, as well as notices addressed to the Insured persons shall be made both in Hungarian and in English and in German, it being understood that in disputes or disagreement the Hungarian wording shall prevail.",
    "Tanúk/Witnesses" : "Tanúk/Witnesses",
    "Név/Name": "Név/Name",
    "Cím/Address" : "Cím/Address",
    "signature_of_insurance" : "Aláírás/Signature",
    "Biztosított személyi adatai/Personal particulars of the Insured" : "Biztosított személyi adatai/Personal particulars of the Insured",
    "Biztosítotti nyilatkozat/Insured’s Declaration" : "Biztosítotti nyilatkozat/Insured’s Declaration",
    "(A nyilatkozatok csak a jelölőnégyzet kitöltésével együtt érvényesek!)<br/>(Declarations are only valid if the respective boxes are checked!)" : "(A nyilatkozatok csak a jelölőnégyzet kitöltésével együtt érvényesek!)<br/>(Declarations are only valid if the respective boxes are checked!)",
    "Nyilatkozatok/Declaration" : "Nyilatkozatok/Declaration",
    "A biztosított egészségügyi adatkezeléssel, egészségügyi titoktartási kötelezettség alóli felmentéssel kapcsolatos nyilatkozata STUDIUM szolgáltatásfinanszírozó egészségbiztosításhoz <br/>The Insured’s consent for the processing of the protected health information, waiver of medical confidentiality obligation for STUDIUM fee-for-service health insurance" : "A biztosított egészségügyi adatkezeléssel, egészségügyi titoktartási kötelezettség alóli felmentéssel kapcsolatos nyilatkozata STUDIUM szolgáltatásfinanszírozó egészségbiztosításhoz <br/>The Insured’s consent for the processing of the protected health information, waiver of medical confidentiality obligation for STUDIUM fee-for-service health insurance",
    "Aláírások/Signatures" : "Aláírások/Signatures"
}

export default translationEn;
