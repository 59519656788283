import * as actionTypes from "./actionTypes";
import {EventInterface} from "../declarations";
import {clearStorage, getStorage, setStorage} from "../plugins/storage";
import * as storageTypes from "../plugins/storageTypes";

const imageToBase64 = require('image-to-base64');

export const clearEvent = () => {
    return (dispatch: any) => {
        clearStorage(storageTypes.EVENT).then(() => {
            dispatch(removeEvent());
        });
    };
};

export const removeEvent = () => {
    return {
        type: actionTypes.CLEAR_EVENT
    };
};

export const updateEvent = (event: EventInterface) => {
    return (dispatch: any) => {
        return new Promise((resolve) => {
            if (event.logo && event.logo.path) {
                const logoPath = process.env.REACT_APP_API_BASE_URL + event.logo.path;

                imageToBase64(logoPath).then((response: any) => {
                    event.logo = 'data:image/png;base64, ' + response;

                    setStorage(storageTypes.EVENT, event).then(() => {
                        dispatch(setEvent(event));
                        resolve();
                    }).catch(err => console.log(err));
                });
            } else {
                dispatch(setEvent(event));
                resolve();
            }
        });
    };
};

export const setEvent = (event: EventInterface) => {
    return {
        type: actionTypes.SET_EVENT,
        event
    };
};

export const initEventFromStorage = () => {
    return (dispatch: any) => {
        return getStorage(storageTypes.EVENT).then((event: any) => {
            console.log('[actions/event.ts] initEventFromStorage done');
            dispatch(setEvent(event));
        });
    }
};
