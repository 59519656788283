import React from "react";
import classes from "./Header.module.scss";
import {IonRow, IonCol} from "@ionic/react";
import Icon from "../Icon/Icon";
import {useSelector} from "react-redux";


const Header = () => {
    const event = useSelector((state: any) => state.event);

    return (
        <IonRow className={classes["header"]}>
            <IonCol className={classes["header__left-side"]}>
                <Icon/>
            </IonCol>

            <IonCol>
                <div className={classes["header__event-details"]}>
                    <h2>Szemeszter: {event.name}</h2>
                </div>
            </IonCol>
        </IonRow>
    );
};

export default Header;
