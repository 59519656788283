export const AUTH_START_LOADING = "AUTH_START_LOADING";
export const AUTH_LOADING_END = "AUTH_LOADING_END";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";

export const SYNC_START = "SYNC_START";
export const SYNC_END = "SYNC_END";
export const SYNC_SUCCESS = "SYNC_SUCCESS";
export const SYNC_FAIL = "SYNC_FAIL";

export const TOAST_SHOW = "TOAST_SHOW";
export const TOAST_HIDE = "TOAST_HIDE";

export const SPINNER_MODAL_SHOW = "SPINNER_MODAL_SHOW";
export const SPINNER_MODAL_HIDE = "SPINNER_MODAL_HIDE";

export const SET_USER = "SET_USER";
export const CLEAR_USER = "USER_CLEAR";

export const SET_EVENT = "SET_EVENT";
export const CLEAR_EVENT = "CLEAR_EVENT";

export const ALERT_SHOW_SUCCESS = 'ALERT_SHOW_SUCCESS';
export const ALERT_HIDE_SUCCESS = 'ALERT_HIDE_SUCCESS';
export const ALERT_SHOW_DANGER = 'ALERT_SHOW_DANGER';
export const ALERT_HIDE_DANGER = 'ALERT_HIDE_DANGER';
export const ALERT_SHOW_SYNC = 'ALERT_SHOW_SYNC';
export const ALERT_HIDE_SYNC = 'ALERT_HIDE_SYNC';
export const ALERT_HIDE = 'ALERT_HIDE';

export const CHART_START = 'CHART_START';
export const CHART_END = 'CHART_END';
export const LOAD_CHARTS_ERROR = 'LOAD_CHARTS_ERROR';
export const SET_CHARTS = 'SET_CHARTS';

