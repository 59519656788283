import React from "react";
import {IonSpinner} from "@ionic/react";

const spinner = () => {
    return (
        <div className="ion-padding ion-text-center"><IonSpinner color="primary"/></div>
    );
};

export default spinner;
