import React from "react";
import {IonCol, IonRow, IonIcon} from "@ionic/react";
import {
    alert, checkmark, sync
} from "ionicons/icons";
import Moment from "react-moment";

import {ChartInterface} from "../../store/declarations";
import {getFieldValue} from "../../utils/functions/form";

import classes from "./Content.module.scss";
import {getFirstChartFormByType, checkIsFormsValid} from "../../utils/functions/chart";
import {emptyInputFields} from "../../store/static/forms/input";
import {PERSONAL_FORM} from "../../utils/functions/constants";
import {DATE_FORMAT} from "../../utils/consants/common";
import ChartControlButtons from "../UI/ChartControlButtons/ChartControlButtons";

interface ContentProps extends ChartInterface {
    onClickHandlers: any;
}

const content: React.FC<ContentProps> = props => {
    const {
        hash,
        last_sync_date,
        onClickHandlers
    } = props;
    const personalForm = getFirstChartFormByType(props, PERSONAL_FORM);
    const isPersonalFormValid = personalForm?.valid ?? 0;
    const isFormsValid = checkIsFormsValid(props);

    let input_fields = emptyInputFields;

    if (personalForm) {
        input_fields = personalForm.input_fields
    }

    let isValidBadgeTemplate = <IonIcon color="danger" icon={alert}/>;

    if (isFormsValid) {
        isValidBadgeTemplate = <IonIcon color="success" icon={checkmark}/>;
    }

    return (
        <IonRow className={classes["content"]}>
            <IonCol>
                <h2 className={classes["content__title"]}>
                    {getFieldValue('last_name', input_fields) + ' ' + getFieldValue('first_name', input_fields)}
                    {isValidBadgeTemplate}
                    {last_sync_date === 0 && <IonIcon color="danger" icon={sync}/>}
                </h2>

                <p className={classes["content__description"]}>
                    <Moment date={new Date(getFieldValue('date_of_birth', input_fields))} format={DATE_FORMAT}/>
                </p>
            </IonCol>

            <IonCol className={classes["content__actions"]}>
                <ChartControlButtons
                    editPersonalFormOnclickHandler={onClickHandlers.editPersonalFormOnclickHandler}
                    hash={hash}
                    isPersonalFormValid={isPersonalFormValid}
                    isFormsValid={isFormsValid}
                />
            </IonCol>
        </IonRow>
    );
};

export default content;
